import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import withAuth from "../../auth";
import Pagination from "../Pagination";
import moment from 'moment';
import { API_URL } from '../../constants';
import { Link, useNavigate } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { AuthContext } from "../AuthContext";

const ContactList = () => {
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [sortBy, setSortBy] = useState('id');
    const [sortOrder, setSortOrder] = useState('desc');
    const [filters, setFilters] = useState({
        // id: '',
        // email: '',
        // name: '',
        // phone: '',
        // message: '',
        // read: '',
        // created_at_start_date: '',
        // created_at_end_date: '',
    });
    const navigate = useNavigate();
    const { token, setToken } = useContext(AuthContext);

    useEffect(() => {
        fetchContacts();
    }, [currentPage, sortBy, sortOrder]);

    const fetchContacts = async (newFilters = null) => {
        setLoading(true);
        try {
            const response = await axios.post(`${API_URL}/api/admin/contacts`,
                {
                    sort_by: sortBy,
                    sort_order: sortOrder,
                    page: currentPage,
                    ...(newFilters || filters),
                },
                {headers: {'Authorization': `Bearer ${token}`}}
            );
            setContacts(response.data.data);
            setTotalPages(response.data.last_page);
        } catch (error) {
            console.error("There was an error fetching the contacts!", error);
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSortChange = (column) => {
        if (sortBy === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(column);
            setSortOrder('asc');
        }
        setCurrentPage(1); // reset pagination when sort
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleFilterChange = (e, column) => {
        const newFilters = {
            ...filters,
            [column]: e.target.value,
        };

        const nonEmptyFilters = Object.keys(newFilters).reduce((acc, key) => {
            if (newFilters[key]) {
                acc[key] = newFilters[key];
            }
            return acc;
        }, {});

        setFilters(nonEmptyFilters);
    };

    const handleSearchSubmit = () => {
        setCurrentPage(1); // reset pagination when filter
        fetchContacts();
    };

    const handleDropdownChange = (e, column) => {
        const newFilters = {
            ...filters,
            [column]: e.target.value,
        };

        const nonEmptyFilters = Object.keys(newFilters).reduce((acc, key) => {
            if (newFilters[key]) {
                acc[key] = newFilters[key];
            }
            return acc;
        }, {});

        setFilters(nonEmptyFilters);
        setCurrentPage(1); // reset pagination when filter
        fetchContacts(nonEmptyFilters);
    };

    return (
        <div className="container-fluid mt-3">
            <h2 className="text-center">Contact List</h2>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <div className="table-responsive">
                    <table className="table table-sm table-striped text-center text-nowrap">
                        <thead>
                        <tr>
                            <th onClick={() => handleSortChange('id')}>
                                ID <i className={`fa fa-sort${sortBy === 'id' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`} />
                            </th>
                            <th onClick={() => handleSortChange('name')}>
                                Name <i className={`fa fa-sort${sortBy === 'name' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`} />
                            </th>
                            <th onClick={() => handleSortChange('email')}>
                                Email <i className={`fa fa-sort${sortBy === 'email' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`} />
                            </th>
                            <th onClick={() => handleSortChange('phone')}>
                                Phone <i className={`fa fa-sort${sortBy === 'phone' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`} />
                            </th>
                            <th>Message</th>
                            <th onClick={() => handleSortChange('read')}>
                                Read <i className={`fa fa-sort${sortBy === 'read' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`} />
                            </th>
                            <th onClick={() => handleSortChange('created_at')}>
                                Created <i className={`fa fa-sort${sortBy === 'created_at' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`} />
                            </th>
                        </tr>
                        <tr>
                            {/* ID */}
                            <td>
                                <div className="input-group">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.id}
                                        placeholder='ID'
                                        onChange={(e) => handleFilterChange(e, 'id')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            {/* Name */}
                            <td>
                                <div className="input-group">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.name}
                                        onChange={(e) => handleFilterChange(e, 'name')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            {/* Email */}
                            <td>
                                <div className="input-group">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.email}
                                        onChange={(e) => handleFilterChange(e, 'email')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            {/* Phone */}
                            <td>
                                <div className="input-group" style={{ minWidth: '140px' }}>
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.phone}
                                        placeholder='204-123-4567'
                                        onChange={(e) => handleFilterChange(e, 'phone')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            {/* Message */}
                            <td>
                                <div className="input-group">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.message}
                                        onChange={(e) => handleFilterChange(e, 'message')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            {/* Read */}
                            <td>
                                <div className="input-group">
                                    <select
                                        className="form-control form-control-sm text-center"
                                        value={filters.read}
                                        onChange={(e) => handleDropdownChange(e, 'read')}
                                    >
                                        <option value="">All</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                </div>
                            </td>
                            {/* Created */}
                            <td>
                                <div className="input-group justify-content-center">
                                    <input type="datetime-local" className="form-control form-control-sm text-center"
                                           style={{maxWidth: '70px'}}
                                           value={filters.created_at_start_date}
                                           onChange={(e) => handleFilterChange(e, 'created_at_start_date')}
                                           onKeyPress={e => {
                                               if (e.key === 'Enter') handleSearchSubmit(e)
                                           }}
                                    />
                                    <input type="datetime-local"
                                           className="form-control form-control-sm text-center"
                                           style={{maxWidth: '70px'}}
                                           value={filters.created_at_end_date}
                                           onChange={(e) => handleFilterChange(e, 'created_at_end_date')}
                                           onKeyPress={e => {
                                               if (e.key === 'Enter') handleSearchSubmit(e)
                                           }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                        </thead>
                        <tbody className="text-wrap">
                        {contacts.map((contact) => (
                            <tr key={contact.id}>
                                <td className={contact.read ? '' : 'table-danger'}>
                                    <Link to={`/contacts/${contact.id}`}>
                                        {contact.id}
                                    </Link>
                                </td>
                                <td>{contact.name}</td>
                                <td>{contact.email}</td>
                                <td>{contact.phone}</td>
                                <td>{contact.message}</td>
                                <td>{contact.read ? 'Yes' : 'No'}</td>
                                <td>{moment(contact.created_at).format('YYYY-MM-DD HH:mm')}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {
                        totalPages > 1 && currentPage < totalPages && (
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                handlePageChange={handlePageChange}
                            />
                        )
                    }
                </div>
            )}
        </div>
    );
};

export default withAuth(ContactList);
