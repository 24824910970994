import React, {useContext, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../components/AuthContext';
import { API_URL } from '../constants';
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";

const Admin2FA = () => {
    const [twoFactorCode, setTwoFactorCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const navigate = useNavigate();
    const {setToken, setAdmin} = useContext(AuthContext);

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
            navigate('/dashboard');
        }
    }, [navigate]);

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        if (name === 'twoFactorCode') {
            setTwoFactorCode(value);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        setErrorMessage('');
        setIsSubmitted(true);
        axios.post(`${API_URL}/api/admin/2fa`, {
            two_factor_code: twoFactorCode
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                const data = response.data;
                localStorage.setItem('authToken', data.token);
                setToken(data.token);
                setAdmin(data.admin);
                navigate('/dashboard');
            })
            .catch(error => {
                console.error('Error:', error);
                if (error.response && error.response.data && error.response.data.error) {
                    setErrorMessage('The 2FA code is invalid or expired. Please try again.');
                } else {
                    setErrorMessage('An error occurred. Please try again later.');
                }
            })
            .finally(() => {
                setIsSubmitted(false);
            });
    };

    return (
        <div className="container mt-5 mb-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <h2 className="text-center mb-4">Admin 2FA Verification</h2>
                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    <form onSubmit={handleSubmit}>
                        <div className="form-group mb-2">
                            <label>2FA Code:</label>
                            <input type="text" name="twoFactorCode" className="form-control" value={twoFactorCode} onChange={handleChange} required autoFocus />
                        </div>
                        <button type="submit" className="btn btn-primary btn-block">
                            {isSubmitted
                                ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;Verify...</>
                                : "Verify"
                            }
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Admin2FA;
