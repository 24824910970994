import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import { API_URL } from '../../constants';
import {Link, useNavigate, useParams} from 'react-router-dom';
import withAuth from "../../auth";
import { Breadcrumb } from 'react-bootstrap';
import moment from "moment";
import {AuthContext} from "../AuthContext";
import Spinner from "react-bootstrap/Spinner";
import DOMPurify from 'dompurify';

const ContactDetails = () => {
    const {contactId} = useParams();
    const [contact, setContact] = useState(null);
    const navigate = useNavigate();
    const {token, setToken, hasPermission} = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    // Fetch the details of the contact
    useEffect(() => {
        setErrorMessage('');
        axios.get(`${API_URL}/api/admin/contacts/${contactId}`, {
            headers: {'Authorization': `Bearer ${token}`}
        })
            .then(response => {
                setContact(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the contact details!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }

                if (error?.response?.data?.errors) {
                    const errors = error.response.data.errors;
                    const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                    setErrorMessage(errorMessages);
                } else if (error?.response?.data?.message) {
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage('An error occurred. Please try again later.');
                }
            })
    }, [contactId]);

    const handleRead = () => {
        setReadStatus(1);
    };
    const handleUnread = () => {
        setReadStatus(0);
    };
    const setReadStatus = (isRead) => {
        setLoading(true);
        axios.put(`${API_URL}/api/admin/contacts/${contactId}/read-status`, {read: isRead},{
            headers: {'Authorization': `Bearer ${token}`}
        })
            .then(response => {
                alert(response.data.message);
                setContact(response.data.contact);
            })
            .catch(error => {
                console.error('There was an error updating the read status!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }

                if (error?.response?.data?.errors) {
                    const errors = error.response.data.errors;
                    const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                    setErrorMessage(errorMessages);
                } else if (error?.response?.data?.message) {
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage('An error occurred. Please try again later.');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDelete = () => {
        if (!window.confirm("Are you sure that you want to delete this contact?")) return;

        axios.delete(`${API_URL}/api/admin/contacts/${contactId}`, {
            headers: {'Authorization': `Bearer ${token}`}
        })
            .then(response => {
                alert('Contact deleted successfully!');
                setContact(null);
                navigate('/contacts');
            })
            .catch(error => {
                console.error('There was an error deleting the contact!', error);
                if (error?.response?.data?.errors) {
                    const errors = error.response.data.errors;
                    const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                    setErrorMessage(errorMessages);
                } else if (error?.response?.data?.message) {
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage('An error occurred. Please try again later.');
                }
            });
    };

    return (
        <div className="container-fluid mt-3">
            <Breadcrumb>
                <Breadcrumb.Item href="/contacts">Contact List</Breadcrumb.Item>
                <Breadcrumb.Item active>Contact Details</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="text-center">Contact Details {contact ? contact.id : ''}</h1>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            {contact ? (
                <div className="row">
                    <div className="col-md-6">
                        <div className="card mb-3">
                            <div className="card-body" align={'center'}>
                                <p className="card-text">
                                    Name: <b>{contact.name}</b><br/>
                                    Email: <b>{contact.email}</b><br/>
                                    Phone: <b>{contact.phone}</b>
                                </p>
                                <p className="card-text">
                                    Created At: <b>{moment(contact.created_at).format('YYYY-MM-DD HH:mm')}</b>
                                    <br/>
                                    Updated At: <b>{contact.updated_at ? moment(contact.updated_at).format('YYYY-MM-DD HH:mm') : 'unknown '}</b>
                                </p>
                                {hasPermission('contact-update') && (
                                    <>
                                        {loading ? (
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        ) : (
                                            <div>
                                                {contact.read ? (
                                                    <button className="btn btn-warning" style={{marginRight: '.5em'}} onClick={handleUnread}>Mark as Unread</button>
                                                ) : (
                                                    <button className="btn btn-success" style={{marginRight: '.5em'}} onClick={handleRead}>Mark as Read</button>
                                                )}
                                            </div>
                                        )}
                                        <hr/>
                                        <button className="btn btn-danger" onClick={handleDelete}>Delete</button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card mb-3">
                            <div className="card-body">
                                <h5 className="text-center">Message</h5>
                                <p className="card-text border rounded bg-light p-2" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(contact.message?.replace(/(\r\n|\r|\n)/g, '<br>'), {ALLOWED_TAGS: ['br']})}}/>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default withAuth(ContactDetails);
