import React from 'react';

const Pagination = ({ currentPage, totalPages, handlePageChange }) => {
    const pagesToShow = 10;
    const startPage = Math.max(currentPage - Math.floor(pagesToShow / 2), 1);
    const endPage = Math.min(currentPage + Math.floor(pagesToShow / 2), totalPages);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <nav>
                <ul className="pagination">
                    {currentPage > 1 &&
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>&laquo;</button>
                        </li>
                    }
                    {startPage > 1 && <li className="page-item"><span className="page-link">...</span></li>}
                    {[...Array(endPage - startPage + 1)].map((_, i) => (
                        <li className={`page-item${currentPage === startPage + i ? ' active' : ''}`} key={i}>
                            <button className="page-link" onClick={() => handlePageChange(startPage + i)}>{startPage + i}</button>
                        </li>
                    ))}
                    {endPage < totalPages && <li className="page-item"><span className="page-link">...</span></li>}
                    {currentPage < totalPages &&
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>&raquo;</button>
                        </li>
                    }
                </ul>
            </nav>
        </div>
    );
};

export default Pagination;
