import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import { API_URL } from '../../constants';
import {Link, useNavigate, useParams} from 'react-router-dom';
import withAuth from "../../auth";
import { Breadcrumb } from 'react-bootstrap';
import moment from "moment";
import Thumbnail from "../Thumbnail";
import {AuthContext} from "../AuthContext";
import CreditCardModal from "./CreditCardModal";
import Spinner from "react-bootstrap/Spinner";
import NPMapViewDirections from "../google_map/NPMapViewDirections";
import { GoogleMapsProvider } from '../google_map/GoogleMapsContext';

const PickupDetails = () => {
    const { pickupId } = useParams();
    const [pickup, setPickup] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {token, setToken, hasPermission} = useContext(AuthContext);
    const [showModal, setShowModal] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [isCancelSubmitted, setIsCancelSubmitted] = useState(false);

    const [deliveryCost, setDeliveryCost] = useState(0.0);
    const [isDeliveryCostSubmitted, setDeliveryCostSubmitted] = useState(false);

    const statusColors = {
        '0': 'transparent',
        '1': '#FFFAE5',  // Very Light Gold
        '2': '#FFCBB2',  // Very Light OrangeRed
        '3': '#CFF3C8',  // Very Light Green
        '4': '#C7D6E9',  // Very Light SteelBlue
        '5': '#D1F2CF',  // Very Light LimeGreen
        '6': '#FFA3B5'   // Very Light Crimson
    };

    useEffect(() => {
        axios.get(`${API_URL}/api/admin/pickups/${pickupId}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            })
            .then(response => {
                setPickup(response.data.data);
            })
            .catch(error => {
                console.error('There was an error fetching the pickup details!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
            });
    }, [pickupId]);

    useEffect(() => {
        setDeliveryCost(pickup?.delivery_cost || 0.0);
    }, [pickup]);

    const handleModalShow = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);

    const handleModalSuccess = (msg, pickup) => {
          setSuccess(msg);
          setPickup(pickup);
    };

    const cancelPickup = async () => {
        if (!window.confirm("Are you sure that you want to cancel this pickup?")) return false;

        setIsCancelSubmitted(true);
        try {
            const response = await axios.post(`${API_URL}/api/admin/pickups/${pickup.id}/cancel`, {}, {
                headers: {'Authorization': `Bearer ${token}`}
            });
            setSuccess(response.data.message);
            setPickup(response.data.pickup);
        }
        catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setError(errorMessages);
            } else if (error?.response?.data?.message) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred. Please try again later.');
            }
        } finally {
            setIsCancelSubmitted(false);
        }
    };

    const saveDeliveryCost = async () => {
        if (!window.confirm("Are you sure that you want to change the delivery cost of this pickup?")) return false;

        setDeliveryCostSubmitted(true);
        try {
            const response = await axios.post(`${API_URL}/api/admin/pickups/${pickup.id}/change-cost`, {cost: deliveryCost}, {
                headers: {'Authorization': `Bearer ${token}`}
            });
            setSuccess(response.data.message);
            setPickup(response.data.pickup);
        }
        catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setError(errorMessages);
            } else if (error?.response?.data?.message) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred. Please try again later.');
            }
        } finally {
            setDeliveryCostSubmitted(false);
        }
    };

    return (
        <div className="container-fluid mt-3">
            <Breadcrumb>
                <Breadcrumb.Item href="/pickups">Pickups</Breadcrumb.Item>
                <Breadcrumb.Item active>Pickup Details</Breadcrumb.Item>
            </Breadcrumb>
            {pickup ? (
                <div>
                    <h1 className="text-center">Pickup Details {pickup ? pickup.id : ''}</h1>
                    {success && <div className="alert alert-success">{success}</div>}
                    {error && <div className="alert alert-danger">{error}</div>}
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card mb-2">
                                <div className="card-body" align="center">
                                    <h6>ID: {pickup.id}</h6>
                                    <h6 className="text-center p-2" style={{ backgroundColor: !pickup.delivery_status?.id ? 'initial' : statusColors[pickup.delivery_status?.id] || 'initial' }}>
                                        Delivery status: {pickup.delivery_status.status}
                                    </h6>
                                    {hasPermission("pickup-update") && (
                                        <>
                                            {!pickup.waiting_for_driver_at && (
                                                <>
                                                    <button className="btn btn-warning mb-2" onClick={handleModalShow}>Book On Behalf</button>
                                                    <CreditCardModal
                                                        userId={pickup?.user?.id}
                                                        pickupId={pickup?.id}
                                                        show={showModal}
                                                        handleClose={handleModalClose}
                                                        handleSuccess={handleModalSuccess}
                                                    />
                                                </>
                                            )}
                                            {pickup.delivery_status.id === 0 && pickup.waiting_for_driver_at && (
                                                <>
                                                    <button className="btn btn-danger mb-2" onClick={cancelPickup}>
                                                        {isCancelSubmitted
                                                            ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;Canceling...</>
                                                            : "Cancel"
                                                        }
                                                    </button>
                                                </>
                                            )}
                                        </>
                                    )}
                                    <p>
                                        Customer: {pickup.user &&
                                        <Link to={`/users/${pickup.user.id}`}>
                                            <b>{pickup.user.id} {pickup.user.full_name}</b> ({pickup.user.email})
                                        </Link>
                                        }
                                        <br/>
                                        Driver: {pickup.driver &&
                                        <Link to={`/drivers/${pickup.driver.id}`}>
                                            <b>{pickup.driver.id} {pickup.driver.name}</b> ({pickup.driver.email})
                                        </Link>
                                        }
                                    </p>
                                    <p className="mb-0">
                                        Truck Type: <strong>{pickup.truck_type.title}</strong>
                                        {pickup.vehicle &&
                                        <span>
                                            <br/>
                                            Vehicle: <Link to={`/vehicles/${pickup.vehicle.id}`}>
                                                <b>{pickup.vehicle.id} {pickup.vehicle.make} {pickup.vehicle.model}</b>
                                            </Link>
                                        </span>}
                                    </p>
                                </div>
                            </div>
                            <ul className="list-group mb-2">
                                {pickup.additional_info && <li className="list-group-item">Additional info: <strong>{pickup.additional_info}</strong></li>}
                                {pickup.claim && <li className="list-group-item">Claim: <strong>{pickup.claim.status}</strong></li>}
                                {pickup.cancelled_by_driver && <li className="list-group-item">
                                    Canceled by driver: <Link to={`/drivers/${pickup.cancelled_by_driver.id}`}>
                                    <b>{pickup.cancelled_by_driver.id} {pickup.cancelled_by_driver.name}</b> ({pickup.cancelled_by_driver.email})
                                </Link>
                                </li>}
                            </ul>

                            <div className="card mb-2">
                                <div className="card-header"><h5 className="mb-0 text-center">Prices</h5></div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className='table table-sm table-striped text-center mb-0'>
                                            <tbody>
                                            <tr>
                                                <td>Cost</td>
                                                <td><b>${pickup.cost}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Delivery cost</td>
                                                {hasPermission("pickup-update") ? (
                                                    <td>
                                                        {pickup.waiting_for_driver_at
                                                            ? <span>${pickup.delivery_cost}</span>
                                                            : <div className="input-group input-group-sm justify-content-center">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text">$</span>
                                                                </div>
                                                                <input type="number"
                                                                       className="form-control text-center input-no-spinners"
                                                                       placeholder="30.00"
                                                                       value={deliveryCost}
                                                                       onChange={(e) => setDeliveryCost(e.target.value)}
                                                                       style={{maxWidth: "75px", flex: '1 1 75px'}}/>
                                                                <div className="input-group-append">
                                                                    <button className="btn btn-outline-secondary" type="button" onClick={saveDeliveryCost}
                                                                            style={{width: '50px'}}>
                                                                        {isDeliveryCostSubmitted
                                                                            ? <><Spinner as="span" animation="border" size="sm"
                                                                                         role="status"
                                                                                         aria-hidden="true"/></>
                                                                            : "Save"
                                                                        }
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        }
                                                    </td>
                                                ) : (
                                                    <td>${pickup.delivery_cost}</td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td>Insurance cost ({pickup.cargo_insurance.insurance_range})</td>
                                                <td>${pickup.insurance_cost}</td>
                                            </tr>
                                            <tr>
                                                <td>GST {parseFloat(pickup.tax_rate?.gst || 0)}%</td>
                                                <td>${pickup.gst_five_percent}</td>
                                            </tr>
                                            <tr>
                                                <td>PST {parseFloat(pickup.tax_rate?.pst || 0)}%</td>
                                                <td>${pickup.pst_seven_percent}</td>
                                            </tr>
                                            <tr>
                                                <td>HST {parseFloat(pickup.tax_rate?.hst || 0)}%</td>
                                                <td>${pickup.hst_amount}</td>
                                            </tr>
                                            <tr>
                                                <td>QST {parseFloat(pickup.tax_rate?.qst || 0)}%</td>
                                                <td>${pickup.qst_amount}</td>
                                            </tr>
                                            <tr>
                                                <td>Total cost</td>
                                                <td><b>${pickup.total_cost}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Driver earnings</td>
                                                <td><b>${pickup.driver_earnings}</b></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <GoogleMapsProvider>
                                <NPMapViewDirections
                                    origins={pickup.origins}
                                    dropoffs={pickup.dropoffs}
                                />
                            </GoogleMapsProvider>
                            <p className="mt-2">
                                Origin: <b>{pickup.origin_address}</b>
                                <br/>
                                Dropoff: <b>{pickup.dropoff_address}</b>
                                <br/>
                                Distance: <b>{pickup.distance} km</b>
                            </p>
                            <div className="card my-2">
                                <div className="card-body">
                                    <h5 className="text-center">Timestamps</h5>
                                    <div className="table-responsive">
                                        <table className="table table-sm table-bordered text-center mb-0">
                                            <tbody>
                                            {pickup.created_at && (
                                                <tr>
                                                    <td>Created at</td>
                                                    <td>{moment(pickup.created_at).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                </tr>
                                            )}
                                            {pickup.waiting_for_driver_at && (
                                                <tr>
                                                    <td>Waiting for driver at</td>
                                                    <td>{moment(pickup.waiting_for_driver_at).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                </tr>
                                            )}
                                            {pickup.claimed_at && (
                                                <tr>
                                                    <td>Claimed at</td>
                                                    <td>{moment(pickup.claimed_at).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                </tr>
                                            )}
                                            {pickup.started_at && (
                                                <tr>
                                                    <td>Started at</td>
                                                    <td>{moment(pickup.started_at).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                </tr>
                                            )}
                                            {pickup.at_pickup_location_at && (
                                                <tr>
                                                    <td>At pickup location at</td>
                                                    <td>{moment(pickup.at_pickup_location_at).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                </tr>
                                            )}
                                            {pickup.going_to_drop_off_at && (
                                                <tr>
                                                    <td>Going to drop off location at</td>
                                                    <td>{moment(pickup.going_to_drop_off_at).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                </tr>
                                            )}
                                            {pickup.at_drop_off_location_at && (
                                                <tr>
                                                    <td>At drop off location at</td>
                                                    <td>{moment(pickup.at_drop_off_location_at).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                </tr>
                                            )}
                                            {pickup.completed_at && (
                                                <tr>
                                                    <td>Completed at</td>
                                                    <td>{moment(pickup.completed_at).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                </tr>
                                            )}
                                            {pickup.canceled_at && (
                                                <tr>
                                                    <td>Canceled at</td>
                                                    <td>{moment(pickup.canceled_at).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-2">
                        <div className="card mb-2">
                            <div className="card-header">
                                <h5 className="mb-0 text-center">Items</h5>

                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className='table table-sm table-striped text-center mb-0'>
                                        <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Photo</th>
                                            <th>Name / Details</th>
                                            <th>Weight / Size</th>
                                            <th>Pickup Address</th>
                                            <th>Dropoff Address</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {pickup.items.map(item => (
                                            <tr key={item.id}>
                                                <td>{item.id}</td>
                                                <td>{item.photo && <a href={item.photo} target="_blank"><img src={item.photo} alt="Item photo" width="50"/></a>}</td>
                                                <td>{item.item_name}<br/>{item.details}</td>
                                                <td>{item.weight}<br/>{item.size}</td>
                                                <td>
                                                    {item.origin && (
                                                        <>
                                                            <b>{item.origin.address}</b>
                                                            <br />
                                                            {item.origin.contact_name && (
                                                                <>
                                                                    {item.origin.contact_name}
                                                                    <br />
                                                                </>
                                                            )}
                                                            {item.origin.contact_phone && (
                                                                <>
                                                                    {item.origin.contact_phone}
                                                                    <br />
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </td>
                                                <td>
                                                    {item.dropoff && (
                                                        <>
                                                            <b>{item.dropoff.address}</b>
                                                            <br />
                                                            {item.dropoff.contact_name && (
                                                                <>
                                                                    {item.dropoff.contact_name}
                                                                    <br />
                                                                </>
                                                            )}
                                                            {item.dropoff.contact_phone && (
                                                                <>
                                                                    {item.dropoff.contact_phone}
                                                                    <br />
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <h5 className="text-center">Delivery Photos</h5>
                        <div className="row">
                            <div className="col-6 mb-2">
                                <div className="card p-2" align="center">
                                    Secured items <br/> {pickup.secured_item
                                    ?
                                    <a href={pickup.secured_item} target="_blank" rel="noopener noreferrer">
                                        <Thumbnail fileUrl={pickup.secured_item} alt="secured_item" />
                                    </a>
                                    : 'Not Uploaded'}
                                </div>
                            </div>
                            <div className="col-6 mb-2">
                                <div className="card p-2" align="center">
                                    Complete photo <br/> {pickup.complete_photo
                                    ?
                                    <a href={pickup.complete_photo} target="_blank" rel="noopener noreferrer">
                                        <Thumbnail fileUrl={pickup.complete_photo} alt="complete_photo" />
                                    </a>
                                    : 'Not Uploaded'}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default withAuth(PickupDetails);
