import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Pagination from "../Pagination";
import moment from 'moment';
import { API_URL } from '../../constants';
import {Link, useNavigate} from "react-router-dom";
import { AuthContext } from "../AuthContext";
import withAuth from "../../auth";

const DeviceList = () => {
    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [sortBy, setSortBy] = useState('id');
    const [sortOrder, setSortOrder] = useState('desc');
    const [filters, setFilters] = useState({
        // id: '',
        // device_id: '',
        // user_id: '',
        // user_type: '',
        // device_model: '',
        // manufacturer: '',
        // os: '',
        // os_version: '',
        // bundle_id: '',
        // app_version: '',
        // created_at_start_date: '',
        // created_at_end_date: ''
    });
    const navigate = useNavigate();
    const { token, setToken } = useContext(AuthContext);

    useEffect(() => {
        fetchDevices();
    }, [currentPage, sortBy, sortOrder]);

    const fetchDevices = async (newFilters = null) => {
        setLoading(true);
        try {
            const response = await axios.post(`${API_URL}/api/admin/devices`,
                {
                    sort_by: sortBy,
                    sort_order: sortOrder,
                    page: currentPage,
                    ...(newFilters || filters),
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            setDevices(response.data.data);
            setTotalPages(response.data.last_page);
        } catch (error) {
            console.error("There was an error fetching the devices!", error);
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSortChange = (column) => {
        if (sortBy === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(column);
            setSortOrder('asc');
        }
        setCurrentPage(1); // reset pagination when sort
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleFilterChange = (e, column) => {
        const newFilters = {
            ...filters,
            [column]: e.target.value,
        };

        const nonEmptyFilters = Object.keys(newFilters).reduce((acc, key) => {
            if (newFilters[key]) {
                acc[key] = newFilters[key];
            }
            return acc;
        }, {});

        setFilters(nonEmptyFilters);
    };

    const handleSearchSubmit = () => {
        setCurrentPage(1); // reset pagination when filter
        fetchDevices();
    };

    const handleUserTypeChange = (e) => {
        const newFilters = {
            ...filters,
            user_type: e.target.value,
        };

        const nonEmptyFilters = Object.keys(newFilters).reduce((acc, key) => {
            if (newFilters[key]) {
                acc[key] = newFilters[key];
            }
            return acc;
        }, {});

        setFilters(nonEmptyFilters);
        setCurrentPage(1); // reset pagination when filter
        fetchDevices(nonEmptyFilters);
    };

    return (
        <div className="container-fluid mt-3">
            <h2 className="text-center">Device List</h2>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <div className="table-responsive">
                    <table className="table table-sm table-striped text-center">
                        <thead>
                        <tr>
                            <th onClick={() => handleSortChange('id')}>
                                ID <i className={`fa fa-sort${sortBy === 'id' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`} />
                            </th>
                            <th onClick={() => handleSortChange('device_id')}>
                                Device ID <i className={`fa fa-sort${sortBy === 'device_id' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`} />
                            </th>
                            <th onClick={() => handleSortChange('user_id')}>
                                User ID <i className={`fa fa-sort${sortBy === 'user_id' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`} />
                            </th>
                            <th onClick={() => handleSortChange('user_type')}>
                                User Type <i className={`fa fa-sort${sortBy === 'user_type' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`} />
                            </th>
                            <th onClick={() => handleSortChange('device_model')}>
                                Device Model <i className={`fa fa-sort${sortBy === 'device_model' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`} />
                            </th>
                            <th onClick={() => handleSortChange('manufacturer')}>
                                Manufacturer <i className={`fa fa-sort${sortBy === 'manufacturer' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`} />
                            </th>
                            <th onClick={() => handleSortChange('os')}>
                                OS <i className={`fa fa-sort${sortBy === 'os' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`} />
                            </th>
                            <th onClick={() => handleSortChange('os_version')}>
                                OS Version <i className={`fa fa-sort${sortBy === 'os_version' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`} />
                            </th>
                            <th onClick={() => handleSortChange('bundle_id')}>
                                Bundle ID <i className={`fa fa-sort${sortBy === 'bundle_id' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`} />
                            </th>
                            <th onClick={() => handleSortChange('app_version')}>
                                App Version <i className={`fa fa-sort${sortBy === 'app_version' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`} />
                            </th>
                            <th onClick={() => handleSortChange('created_at')}>
                                Created At <i className={`fa fa-sort${sortBy === 'created_at' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`} />
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <div className="input-group justify-content-center">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        value={filters.id}
                                        placeholder="ID"
                                        onChange={(e) => handleFilterChange(e, 'id')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            <td>
                                <div className="input-group justify-content-center">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        value={filters.device_id}
                                        placeholder="Device ID"
                                        onChange={(e) => handleFilterChange(e, 'device_id')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            <td>
                                <div className="input-group justify-content-center">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        value={filters.user_id}
                                        placeholder="User ID"
                                        onChange={(e) => handleFilterChange(e, 'user_id')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            <td>
                                <div className="input-group justify-content-center">
                                    <select
                                        className="form-control form-control-sm text-center"
                                        value={filters.user_type}
                                        onChange={handleUserTypeChange}
                                    >
                                        <option value="">All</option>
                                        <option value="0">Empty</option>
                                        <option value="1">Customer</option>
                                        <option value="2">Driver</option>
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div className="input-group justify-content-center">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        value={filters.device_model}
                                        placeholder="Device Model"
                                        onChange={(e) => handleFilterChange(e, 'device_model')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            <td>
                                <div className="input-group justify-content-center">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        value={filters.manufacturer}
                                        placeholder="Manufacturer"
                                        onChange={(e) => handleFilterChange(e, 'manufacturer')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            <td>
                                <div className="input-group justify-content-center">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        value={filters.os}
                                        placeholder="OS"
                                        onChange={(e) => handleFilterChange(e, 'os')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            <td>
                                <div className="input-group justify-content-center">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        value={filters.os_version}
                                        placeholder="OS Version"
                                        onChange={(e) => handleFilterChange(e, 'os_version')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            <td>
                                <div className="input-group justify-content-center">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        value={filters.bundle_id}
                                        placeholder="Bundle ID"
                                        onChange={(e) => handleFilterChange(e, 'bundle_id')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            <td>
                                <div className="input-group justify-content-center">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        value={filters.app_version}
                                        placeholder="App Version"
                                        onChange={(e) => handleFilterChange(e, 'app_version')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            <td>
                                <div className="input-group justify-content-center">
                                    <input
                                        type="datetime-local"
                                        className="form-control form-control-sm text-center"
                                        style={{maxWidth: '80px'}}
                                        value={filters.created_at_start_date}
                                        onChange={(e) => handleFilterChange(e, 'created_at_start_date')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <input
                                        type="datetime-local"
                                        className="form-control form-control-sm text-center"
                                        style={{maxWidth: '80px'}}
                                        value={filters.created_at_end_date}
                                        onChange={(e) => handleFilterChange(e, 'created_at_end_date')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        {devices.map((device) => (
                            <tr key={device.id}>
                                <td>{device.id}</td>
                                <td>{device.device_id}</td>
                                <td>
                                    {device.user_id > 0 && (
                                        <>
                                            {device.user_type_id == 1 && <Link to={"/users/" + device.user_id}>{device.user_id}</Link>}
                                            {device.user_type_id == 2 && <Link to={"/drivers/" + device.user_id}>{device.user_id}</Link>}
                                        </>
                                    )}
                                </td>
                                <td>{device.user_type}</td>
                                <td>{device.device_model}</td>
                                <td>{device.manufacturer}</td>
                                <td>{device.os}</td>
                                <td>{device.os_version}</td>
                                <td>{device.bundle_id}</td>
                                <td>{device.app_version}</td>
                                <td>{moment(device.created_at).format('YYYY-MM-DD HH:mm')}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {totalPages > 1 && currentPage <= totalPages &&
                    <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange}/>
                    }
                </div>
            )}
        </div>
    );
};

export default withAuth(DeviceList);
