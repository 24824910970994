import {useNavigate, useParams} from "react-router-dom";
import {Breadcrumb} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../AuthContext";
import axios from "axios";
import {API_URL} from "../../constants";
import withAuth from "../../auth";

const RoleDetails = () => {
    const {roleId} = useParams();
    const [role, setRole] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const navigate = useNavigate();
    const {token, setToken} = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [formData, setFormData] = useState({name: '', guard_name: ''});
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        const fetchRoleAndPermissions = async () => {
            try {
                setLoading(true);
                const roleResponse = await axios.get(`${API_URL}/api/admin/roles/${roleId}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                const permissionsResponse = await axios.get(`${API_URL}/api/admin/permissions`, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                const fetchedPermissions = permissionsResponse.data.data;
                console.log('fetchedPermissions', fetchedPermissions);
                const rolePermissions = roleResponse.data.data.permissions;

                const permissionsWithChecked = fetchedPermissions.map(permission => ({
                    ...permission,
                    checked: rolePermissions.some(rp => rp.id === permission.id),
                    loading: false,
                }));

                setRole(roleResponse.data.data);
                setPermissions(permissionsWithChecked);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem("authToken");
                    setToken(null);
                    navigate("/");
                } else {
                    setErrorMessage("An error occurred. Please try again later.");
                }
            } finally {
                setLoading(false);
            }
        };

        fetchRoleAndPermissions();
    }, [roleId, token, navigate, setToken]);

    useEffect(() => {
        if (role) {
            setFormData({
                name: role.name || '',
                guard_name: role.guard_name || '',
            });
        }
    }, [role]);

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSave = async () => {
        if (!window.confirm("Are you sure that you want to update the role's data?")) {
            return;
        }

        setErrorMessage('');
        setIsSubmitted(true);
        try {
            const response = await axios.put(`${API_URL}/api/admin/roles/${roleId}`, formData, {
                headers: {'Authorization': `Bearer ${token}`}
            });
            alert(response.data.message);
            setRole(response.data.role);
        }
        catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setErrorMessage(errorMessages);
            } else if (error?.response?.data?.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('An error occurred. Please try again later.');
            }
        }
        finally {
            setIsSubmitted(false);
        }
    };

    const handleDeleteClick = () => {
        if (!window.confirm("Are you sure that you want to delete this role?")) return;

        axios.delete(`${API_URL}/api/admin/roles/${roleId}`, {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => {
                alert('Role deleted successfully!');
                setRole(null);
                navigate('/coworkers/roles');
            })
            .catch(error => {
                console.error('There was an error deleting the role!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
            });
    };


    const handleChangePermission = async (permissionId, isChecked) => {
        permissionId = parseInt(permissionId);

        setPermissions(permissions.map(p => p.id === permissionId ? { ...p, loading: true } : p));
        try {
            if (isChecked) {
                await axios.post(`${API_URL}/api/admin/roles/${roleId}/add-permission/${permissionId}`, {}, {
                    headers: { Authorization: `Bearer ${token}` }
                });
            } else {
                await axios.delete(`${API_URL}/api/admin/roles/${roleId}/remove-permission/${permissionId}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
            }

            setPermissions(permissions.map(p => p.id === permissionId ? { ...p, checked: isChecked, loading: false } : p));
        } catch (error) {
            setPermissions(permissions.map(p => p.id === permissionId ? { ...p, loading: false } : p));

            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            setErrorMessage(error?.response?.data?.message || "An error occurred. Please try again later.");
        }
    };

    const handleChangeInput = (e) => {
        const { id, checked } = e.target;
        handleChangePermission(id, checked);
    };

    return <div className="container-fluid mt-3">
        <Breadcrumb>
            <Breadcrumb.Item href="/coworkers/roles">Roles</Breadcrumb.Item>
            <Breadcrumb.Item active>Role {roleId}</Breadcrumb.Item>
        </Breadcrumb>
        <h1 className="text-center">Role {roleId}</h1>
        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        {loading ? (
            <div className="d-flex justify-content-center align-items-center" style={{height: '80vh'}}>
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        ) : (
            <div className="row">
                <div className="col-lg-6">
                    <div className="card mb-3">
                        <h2 className='text-center mt-1'>Edit</h2>
                        <div className="card-body" align={'center'}>

                            <div className="form-group row mb-2">
                                <label className="col-md-3 col-form-label">Name:</label>
                                <div className="col-md-9">
                                    <input type="text" className="form-control" name="name" value={formData.name}
                                           onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <label className="col-md-3 col-form-label">Guard Name:</label>
                                <div className="col-md-9">
                                    <input type="text" className="form-control" name="guard_name"
                                           value={formData.guard_name}
                                           onChange={handleChange}

                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button className="btn btn-primary" onClick={handleSave}>
                                    {isSubmitted
                                        ? <><Spinner as="span" animation="border" size="sm" role="status"
                                                     aria-hidden="true"/>&nbsp;Saving...</>
                                        : "Save"
                                    }
                                </button>
                            </div>
                            <hr/>
                            <button className="btn btn-danger" onClick={handleDeleteClick}>Delete</button>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className="card mb-3">
                        <h2 className='text-center mt-1'>Permissions</h2>
                        <div className="card-body">
                            {permissions.length > 0 ? permissions.map((permission, idx) => (
                                <div key={idx} className="form-check">
                                    {!permission.loading ? (
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={permission.id}
                                            checked={permission.checked}
                                            onChange={handleChangeInput}
                                            disabled={permission.loading}
                                        />
                                    ) : (
                                        <Spinner className="form-check-input" as="span" animation="border" size="sm" />
                                    )}
                                    <label htmlFor={permission.id} className="form-check-label">
                                        {permission.name} (<span title="Guard Name">{permission.guard_name}</span>)
                                    </label>
                                    <hr/>
                                </div>
                            )) : <p>No permissions available.</p>}
                        </div>
                    </div>
                </div>
            </div>
        )}
    </div>
};

export default withAuth(RoleDetails)
