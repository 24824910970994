import React, {useContext, useState} from 'react';
import {Modal} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import {API_URL} from "../../constants";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../AuthContext";
import withAuth from "../../auth";

const CoworkerAssignModal = ({adminId, showModal, handleClose, handleSuccess}) => {
    const navigate = useNavigate();
    const {token, setToken} = useContext(AuthContext);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({role: '', guard_name: 'admin'});

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        try {
            const response = await axios.post(`${API_URL}/api/admin/admins/${adminId}/assign-role`, formData, {
                headers: {'Authorization': `Bearer ${token}`}
            });

            if (response.data.message) {
                handleSuccess(response.data.message, response.data.data);
                handleClose();
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setError(errorMessages);
            } else if (error?.response?.data?.message) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred. Please try again later.');
            }
        } finally {
            setIsSubmitted(false);
        }
    };

    return (
        <Modal show={showModal} onHide={handleClose} size="lg">
            <Modal.Header>
                <Modal.Title>Assign a role to the coworker</Modal.Title>
                <button type="button" className="close" onClick={handleClose}>
                    <i className="fa fa-times"/>
                </button>
            </Modal.Header>
            <Modal.Body>
                {error && <div className="alert alert-danger">{error}</div>}
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="card-body" align={'center'}>
                            <form onSubmit={handleSubmit} className="needs-validation" noValidate>
                                <div className="mb-3">
                                    <label htmlFor="first_name" className="form-label">Role</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="role"
                                        name="role"
                                        value={formData.role}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="guard_name" className="form-label">Guard Name</label>
                                    <input
                                        type="guard_name"
                                        className="form-control"
                                        id="guard_name"
                                        name="guard_name"
                                        autoComplete="new-name"
                                        value={formData.guard_name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <button type="submit" className="btn btn-primary">
                                    {isSubmitted
                                        ? <><Spinner as="span" animation="border" size="sm" role="status"
                                                     aria-hidden="true"/>&nbsp;Assigning...</>
                                        : "Assign"
                                    }
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default withAuth(CoworkerAssignModal);
