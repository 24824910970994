import React, {useContext, useEffect, useState} from 'react';
import withAuth from "../auth";
import { API_URL } from '../constants';
import {AuthContext} from "./AuthContext";
import axios from "axios";
import {Link, useNavigate} from "react-router-dom";
import ReactApexChart from 'react-apexcharts';

const Dashboard = () => {
    const [loading, setLoading] = useState(false);
    const [totals, setTotals] = useState({});
    const {token, setToken, admin, setAdmin, hasPermission} = useContext(AuthContext);
    const navigate = useNavigate();
    const [chartData, setChartData] = useState({ series: [], categories: [] });
    const [chartWeeklyTotals, setChartWeeklyTotals] = useState({});

    const chartOptions = {
        chart: {
            height: 249,
            type: 'bar',
            toolbar: {
                show: false,
            },
            fontFamily: 'Nunito, sans-serif',
        },
        colors: ["#036fe7", "#00ff0c", "#f93a5a"],
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: false
                },
                columnWidth: '40%',
                endingShape: 'rounded',
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            endingShape: 'rounded',
            colors: ['transparent'],
        },
        responsive: [{
            breakpoint: 576,
            options: {
                stroke: {
                    show: true,
                    width: 1,
                    endingShape: 'rounded',
                    colors: ['transparent'],
                },
            },
        }],
        xaxis: {
            categories: [],
        },
        fill: {
            opacity: 1
        },
        legend: {
            show: false,
            floating: true,
            position: 'top',
            horizontalAlign: 'left',
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val;
                }
            }
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return Math.round(val); // Round to nearest whole number
                }
            },
        },
    };

    useEffect(() => {
        setLoading(true);
        axios.get(`${API_URL}/api/admin/dashboard/totals`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                setTotals(response.data);

                setLoading(true);
                axios.get(`${API_URL}/api/admin/dashboard/pickup-stats`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                })
                    .then(response => {
                        setChartWeeklyTotals(response.data?.weekly_totals);

                        const dailyStats = response.data?.daily_stats;
                        const categories = dailyStats.map(item => item.date);
                        const created = dailyStats.map(item => item.total_pickups);
                        const delivered_pickups = dailyStats.map(item => item.delivered_pickups);
                        const canceled = dailyStats.map(item => item.canceled_pickups);

                        setChartData({
                            series: [
                                { name: 'Total Pickups', data: created },
                                { name: 'Delivered Pickups', data: delivered_pickups },
                                { name: 'Canceled Pickups', data: canceled }
                            ],
                            categories: categories
                        });
                    })
                    .catch(error => {
                        console.error('There was an error fetching the admin details!', error);
                        if (error.response && error.response.status === 401) {
                            localStorage.removeItem('authToken');
                            setToken(null);
                            navigate('/');
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            })
            .catch(error => {
                console.error('There was an error fetching the admin details!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [navigate, setToken, token]);

    return (
        <div className="container mt-3">
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <>
                    {hasPermission("dashboard-stat-view") && (
                        <div className="row d-sm-flex">
                        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                            <div className="card overflow-hidden sales-card bg-primary-gradient">
                                <div className="pl-3 pt-2 pr-3 pb-2 pt-0">
                                    <div className="pb-0 mt-0">
                                        <Link to="/users">
                                            <div className="row">
                                                <div className="col-6">
                                                    <h4 className="tx-13 font-weight-bold mb-1 text-white">Customers:</h4>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <h4 className="tx-18 font-weight-bold mb-1 text-white">
                                                        {totals?.customers?.all || 0}<br/>
                                                        today:&nbsp;+{totals?.customers?.today || 0}
                                                    </h4>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                            <div className="card overflow-hidden sales-card bg-danger-gradient">
                                <div className="pl-3 pt-2 pr-3 pb-2 pt-0">
                                    <div className="pb-0 mt-0">
                                        <Link to="/drivers">
                                            <div className="row">
                                                <div className="col-6">
                                                    <h4 className="tx-13 font-weight-bold mb-1 text-white">Drivers:</h4>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <h4 className="tx-18 font-weight-bold mb-1 text-white">
                                                        {totals?.drivers?.all || 0}<br/>
                                                        today:&nbsp;+{totals?.drivers?.today || 0}
                                                    </h4>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12 ">
                            <div className="card overflow-hidden sales-card bg-success-gradient">
                                <div className="pl-3 pt-2 pr-3 pb-2 pt-0">
                                    <div className="pb-0 mt-0">
                                        <Link to="/pickups">
                                            <div className="row">
                                                <div className="col-5">
                                                    <h4 className="tx-13 font-weight-bold mb-1 text-white">Pickups:</h4>
                                                </div>
                                                <div className="col-7 text-right">
                                                    <h4 className="tx-18 font-weight-bold mb-1 text-white">
                                                        {totals?.pickups?.all || 0}<br />
                                                        today:&nbsp;+{totals?.pickups?.today || 0}
                                                    </h4>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                            <div className="card overflow-hidden sales-card bg-warning-gradient">
                                <div className="pl-3 pt-2 pr-3 pb-2 pt-0">
                                    <div className="pb-0 mt-0">
                                        <Link to="/">
                                            <div className="row">
                                                <div className="col-6">
                                                    <h4 className="tx-13 font-weight-bold mb-1 text-white">Weekly driver earnings:</h4>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <h4 className="tx-18 font-weight-bold mb-1 text-white">${totals?.driver_earnings || 0.00}<br /><br /></h4>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    )}

                    <div>
                        <p className="text-center">Welcome {admin?.name}!</p>
                        {!admin?.slack_user_id && (
                            <h5 className='heading-info text-center pt-2 pb-2'>
                                Slack 2FA <br />
                                <small>
                                    Please setup Slack for more convenient 2FA access <br />
                                    <a href={`${API_URL}/admin/login/slack`}>Setup Slack 2FA</a>
                                </small>
                            </h5>
                        )}
                    </div>

                    {hasPermission("dashboard-stat-view") && (
                        <div className="row row-sm">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header bg-transparent pd-b-0 pd-t-20 bd-b-0">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mb-0">Pickups for the Week</h4>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="total-revenue">
                                        <div>
                                            <h4>{chartWeeklyTotals.created_pickups}</h4>
                                            <label><span className="bg-primary"/>Total</label>
                                        </div>
                                        <div>
                                            <h4>{chartWeeklyTotals.paid_pickups}</h4>
                                            <label><span className="bg-success"/>Delivered</label>
                                        </div>
                                        <div>
                                            <h4>{chartWeeklyTotals.canceled_pickups}</h4>
                                            <label><span className="bg-danger"/>Canceled</label>
                                        </div>
                                    </div>
                                    <div id="bar" className="sales-bar mt-4">
                                        <ReactApexChart
                                            options={{ ...chartOptions, xaxis: { categories: chartData.categories } }}
                                            series={chartData.series}
                                            type="bar"
                                            height={249}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                </>
            )}
        </div>
    );
};

export default withAuth(Dashboard);
