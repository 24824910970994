import { useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../AuthContext";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { API_URL } from "../../constants";
import withAuth from "../../auth";
import moment from "moment";
import {Breadcrumb} from "react-bootstrap";

const PromoCodeDetails = () => {
    const { id } = useParams();
    const [promoCode, setPromoCode] = useState(null);
    const navigate = useNavigate();
    const { token, setToken, hasPermission } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        code: '',
        discount_type: 'percentage',
        discount_value: '',
        valid_from: '',
        valid_to: '',
        usage_limit: '',
        is_active: true
    });

    useEffect(() => {
        const fetchPromoCode = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/admin/promo-codes/${id}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                const promoCode = response.data.data;
                setPromoCode(promoCode);

                setFormData({
                    code: promoCode.code,
                    discount_type: promoCode.discount_type,
                    discount_value: promoCode.discount_value,
                    valid_from: promoCode.valid_from,
                    valid_to: promoCode.valid_to,
                    usage_limit: promoCode.usage_limit,
                    is_active: promoCode.is_active
                });
                setLoading(false);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                } else {
                    setError('Failed to load promo code.');
                }
                setLoading(false);
            }
        };
        fetchPromoCode();
    }, [id, token, setToken, navigate]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? (checked ? 1 : 0) : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setError('');
        setIsSubmitted(true);
        try {
            const response = await axios.put(`${API_URL}/api/admin/promo-codes/${id}`, formData, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (response.data.message) {
                alert(response.data.message);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setError(errorMessages);
            } else if (error?.response?.data?.message) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred. Please try again later.');
            }
        } finally {
            setIsSubmitted(false);
        }
    };

    const handleDelete = async () => {
        if (!window.confirm('Are you sure you want to delete this promo code?')) return;

        try {
            await axios.delete(`${API_URL}/api/admin/promo-codes/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            alert('Promo code deleted successfully.');
            navigate('/promo-codes');
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            } else {
                setError('Failed to delete promo code.');
            }
        }
    };

    return (
        <div className="container-fluid mt-3">
            <Breadcrumb>
                <Breadcrumb.Item href="/promo-codes">Promo Code List</Breadcrumb.Item>
                <Breadcrumb.Item active>Details</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="text-center">Promo Code Details</h1>
            {error && <div className="alert alert-danger">{error}</div>}
            {promoCode ? (
                <div className="row justify-content-center">
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body" align={'center'}>
                            {hasPermission("promo-code-update") &&
                                <form onSubmit={handleSubmit} className="needs-validation" noValidate>
                                <div className="mb-3">
                                    <label htmlFor="code" className="form-label">Promo Code</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="code"
                                        name="code"
                                        value={formData.code}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="discount_type" className="form-label">Discount Type</label>
                                    <select
                                        className="form-control"
                                        id="discount_type"
                                        name="discount_type"
                                        value={formData.discount_type}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="percentage">Percentage</option>
                                        <option value="fixed">Fixed</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="discount_value" className="form-label">Discount Value</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="discount_value"
                                        name="discount_value"
                                        value={formData.discount_value}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="valid_from" className="form-label">Valid From</label>
                                    <input
                                        type="datetime-local"
                                        className="form-control"
                                        id="valid_from"
                                        name="valid_from"
                                        value={moment(formData.valid_from).format('YYYY-MM-DD HH:mm')}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="valid_to" className="form-label">Valid To</label>
                                    <input
                                        type="datetime-local"
                                        className="form-control"
                                        id="valid_to"
                                        name="valid_to"
                                        value={moment(formData.valid_to).format('YYYY-MM-DD HH:mm')}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="usage_limit" className="form-label">Usage Limit</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="usage_limit"
                                        name="usage_limit"
                                        value={formData.usage_limit}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3 form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="is_active"
                                        name="is_active"
                                        checked={!!formData.is_active}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="is_active" className="form-check-label">Active</label>
                                </div>
                                <button type="submit" className="btn btn-primary">
                                    {isSubmitted
                                        ? <><Spinner as="span" animation="border" size="sm" role="status"
                                                     aria-hidden="true"/>&nbsp;Updating...</>
                                        : "Update"
                                    }
                                </button>
                                <hr/>
                                <button type="button" className="btn btn-danger ms-2" onClick={handleDelete}>
                                    Delete
                                </button>
                            </form>
                            }
                            {!hasPermission("promo-code-update") && (
                                <>
                                    <div className="table-responsive">
                                        <table className='table table-sm table-bordered text-center mb-0'>
                                            <tbody>
                                            <tr>
                                                <td>ID</td>
                                                <td><b>{promoCode.id}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Code</td>
                                                <td><b>{promoCode.code}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Discount Type</td>
                                                <td><b>{promoCode.discount_type}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Discount Value</td>
                                                <td><b>{promoCode.discount_type === 'percentage' ? promoCode.discount_value + '%' : '$' + promoCode.discount_value}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Valid From</td>
                                                <td><b>{moment(promoCode.valid_from).format('YYYY-MM-DD HH:mm')}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Valid To</td>
                                                <td><b>{moment(promoCode.valid_to).format('YYYY-MM-DD HH:mm')}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Usage</td>
                                                <td><b>{promoCode.times_used}/{promoCode.usage_limit}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Activate</td>
                                                <td><b>{promoCode.is_active ? 'Yes' : 'No'}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Created At</td>
                                                <td><b>{moment(promoCode.created_at).format('YYYY-MM-DD HH:mm')}</b></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            ) : (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default withAuth(PromoCodeDetails);
