import {useNavigate} from "react-router-dom";
import React, {useContext, useState} from "react";
import {AuthContext} from "../AuthContext";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import {API_URL} from "../../constants";
import withAuth from "../../auth";

const PermissionCreate = () => {
    const navigate = useNavigate();
    const {token, setToken} = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [error, setError] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formData, setFormData] = useState({name: '', guard_name: 'admin'});

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setError('');
        setIsSubmitted(true);
        try {
            const response = await axios.post(`${API_URL}/api/admin/permissions`, formData, {
                headers: {'Authorization': `Bearer ${token}`}
            });

            if (response.data.message) {
                alert(response.data.message);
                navigate('/coworkers/permissions');
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setError(errorMessages);
            } else if (error?.response?.data?.message) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred. Please try again later.');
            }
        } finally {
            setIsSubmitted(false);
        }
    };

    return (
        <div className="container mt-5">
            <h1 className="text-center">Create a new permission</h1>
            {error && <div className="alert alert-danger">{error}</div>}
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body" align={'center'}>
                            <form onSubmit={handleSubmit} className="needs-validation" noValidate>
                                <div className="mb-3">
                                    <label htmlFor="first_name" className="form-label">Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="guard_name" className="form-label">Guard Name</label>
                                    <input
                                        type="guard_name"
                                        className="form-control"
                                        id="guard_name"
                                        name="guard_name"
                                        autoComplete="new-name"
                                        value={formData.guard_name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <button type="submit" className="btn btn-primary">
                                    {isSubmitted
                                        ? <><Spinner as="span" animation="border" size="sm" role="status"
                                                     aria-hidden="true"/>&nbsp;Creating...</>
                                        : "Create"
                                    }
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default withAuth(PermissionCreate);
