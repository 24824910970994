import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useGoogleMaps } from './GoogleMapsContext';

const NPPlacesInput = (props) => {
    const { isLoaded, loadError } = useGoogleMaps();
    const [address, setAddress] = useState('');

    const handleChange = (value) => {
        setAddress(value);
    };

    const handleSelect = (value) => {
        setAddress(value);

        geocodeByAddress(value)
            .then(results => getLatLng(results[0]))
            .then(latlng => {
                props.onResultSelected(value, latlng);
            })
            .catch(error => {
                console.error('Error getting latLng', error);
            });
    };

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <PlacesAutocomplete
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className="autocomplete">
                    <input {...getInputProps({ placeholder: props.placeholder, className: "form-control" })} />
                    <div className="autocomplete-items">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion, index) => {
                            const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                                <div
                                    {...getSuggestionItemProps(suggestion, { style })}
                                    key={index}
                                >
                                    {suggestion.description}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    );
};

export default NPPlacesInput;
