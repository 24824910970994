import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { API_URL } from '../../constants';
import { AuthContext } from "../AuthContext";
import withAuth from "../../auth";
import {useNavigate} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

const TaxRateList = () => {
    const [taxRates, setTaxRates] = useState(null);
    const [updatingId, setUpdatingId] = useState(null);
    const {token, setToken, hasPermission} = useContext(AuthContext);
    const navigate = useNavigate();

    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchTaxRates = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/admin/tax-rates`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setTaxRates(response.data.data);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
                if (error?.response?.data?.errors) {
                    const errors = error.response.data.errors;
                    const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                    setError(errorMessages);
                } else if (error?.response?.data?.message) {
                    setError(error.response.data.message);
                } else {
                    setError('An error occurred. Please try again later.');
                }
            }
        };

        fetchTaxRates();
    }, [token]);

    const handleChange = (e, id) => {
        const { name, value } = e.target;
        setTaxRates(taxRates.map(tax =>
            tax.id === id ? { ...tax, [name]: value } : tax
        ));
    };

    const handleUpdate = async (id) => {
        if (!window.confirm("Are you sure that you want to update the Tax Rate?")) return false;

        setSuccess('');
        setError('');
        setUpdatingId(id);
        const taxToUpdate = taxRates.find(tax => tax.id === id);
        try {
            await axios.put(`${API_URL}/api/admin/tax-rates/${id}`, taxToUpdate, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setSuccess(`Tax Rate #${id} has been successfully updated`);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setError(errorMessages);
            } else if (error?.response?.data?.message) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred. Please try again later.');
            }
        } finally {
            setUpdatingId(null);
        }
    };

    return (
        <div className="container mt-5">
            <h1 className="text-center">Tax Rates</h1>
            {success && <div className="alert alert-success">{success}</div>}
            {error && <div className="alert alert-danger">{error}</div>}
            {taxRates ? (
                <div className="table-responsive">
                    <table className="table table-hover text-center">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Province</th>
                            <th>GST, %</th>
                            <th>PST, %</th>
                            <th>HST, %</th>
                            <th>QST, %</th>
                            {hasPermission("tax-rate-update") && <th>Action</th>}
                        </tr>
                        </thead>
                        <tbody>
                        {taxRates.map((tax) => (
                            <tr key={tax.id}>
                                <td style={{verticalAlign: 'middle'}}>{tax.id}</td>
                                <td>{tax.province}</td>
                                <td style={{width: '10%', minWidth: '90px'}}>
                                    {hasPermission("tax-rate-update") ? (
                                        <input
                                            type="number"
                                            name="gst"
                                            value={parseFloat(tax.gst)}
                                            onChange={(e) => handleChange(e, tax.id)}
                                            step="0.01"
                                            className="form-control"
                                        />
                                    ) : parseFloat(tax.gst)}
                                </td>
                                <td style={{width: '10%', minWidth: '90px'}}>
                                    {hasPermission("tax-rate-update") ? (
                                        <input
                                            type="number"
                                            name="pst"
                                            value={parseFloat(tax.pst)}
                                            onChange={(e) => handleChange(e, tax.id)}
                                            step="0.01"
                                            className="form-control"
                                        />
                                    ) : parseFloat(tax.pst)}
                                </td>
                                <td style={{width: '10%', minWidth: '90px'}}>
                                    {hasPermission("tax-rate-update") ? (
                                        <input
                                            type="number"
                                            name="hst"
                                            value={parseFloat(tax.hst)}
                                            onChange={(e) => handleChange(e, tax.id)}
                                            step="0.01"
                                            className="form-control"
                                        />
                                    ) : parseFloat(tax.hst)}
                                </td>
                                <td style={{width: '10%', minWidth: '90px'}}>
                                    {hasPermission("tax-rate-update") ? (
                                        <input
                                            type="number"
                                            name="qst"
                                            value={parseFloat(tax.qst)}
                                            onChange={(e) => handleChange(e, tax.id)}
                                            step="0.01"
                                            className="form-control"
                                        />
                                    ) : parseFloat(tax.qst)}
                                </td>
                                {hasPermission("tax-rate-update") && (
                                    <td>
                                        <button className="btn btn-success" onClick={() => handleUpdate(tax.id)} disabled={updatingId === tax.id && updatingId !== null}>
                                            {updatingId === tax.id ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Update'}
                                        </button>
                                    </td>
                                )}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default withAuth(TaxRateList);
