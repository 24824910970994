import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, ListGroup } from 'react-bootstrap';
import axios from 'axios';
import {API_URL, STRIPE_PUBLISH_KEY} from '../../constants';
import { AuthContext } from '../AuthContext';
import CardForm from '../CardForm';
import Spinner from "react-bootstrap/Spinner";
import withAuth from "../../auth";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import {useNavigate} from "react-router-dom";

const stripePromise = loadStripe(`${STRIPE_PUBLISH_KEY}`);

const CreditCardModal = ({ userId, pickupId, show, handleClose, handleSuccess }) => {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [loading, setLoading] = useState(false);
    const { token, setToken } = useContext(AuthContext);
    const navigate = useNavigate();
    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (show) {
            fetchPaymentMethods();
        }
    }, [show, userId]);

    const fetchPaymentMethods = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${API_URL}/api/admin/users/${userId}/payment-methods`, {
                headers: {'Authorization': `Bearer ${token}`}
            });
            setPaymentMethods(response.data.data);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setError(errorMessages);
            } else if (error?.response?.data?.message) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred. Please try again later.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleWaitForDriver = async () => {
        if (!selectedPaymentMethodId) {
            alert('Please select a payment method.');
            return;
        }

        setIsSubmitted(true);
        try {
            const response = await axios.post(`${API_URL}/api/admin/pickups/${pickupId}/waiting-for-driver`, {
                payment_method_id: selectedPaymentMethodId,
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            handleSuccess(`The pickup ${pickupId} has been successfully marked as waiting for a driver.`, response.data.pickup);
            handleClose();
        }
        catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setError(errorMessages);
            } else if (error?.response?.data?.message) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred. Please try again later.');
            }
        } finally {
            setIsSubmitted(false);
        }
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header>
                <Modal.Title>Choose or Add a Credit Card</Modal.Title>
                <button type="button" className="close" onClick={handleClose}>
                    <i className="fa fa-times"/>
                </button>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <div className="d-flex justify-content-center">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                ) : (
                    <>
                        {success && <div className="alert alert-success">{success}</div>}
                        {error && <div className="alert alert-danger">{error}</div>}
                        <ListGroup>
                            {paymentMethods.map((method, index) => (
                                <ListGroup.Item
                                    key={index}
                                    action
                                    onClick={() => setSelectedPaymentMethodId(method.id)}
                                    active={selectedPaymentMethodId === method.id}
                                    className="d-flex justify-content-between align-items-center"
                                >
                                    <div>
                                        {method.card.brand} **** {method.card.last4} - Expires {method.card.exp_month}/{method.card.exp_year}
                                    </div>
                                    {selectedPaymentMethodId === method.id && (
                                        <i className="fas fa-check" style={{ color: '#28a745' }}/>
                                    )}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                        {paymentMethods.length === 0 && <p>No payment methods available. Add a new one below.</p>}
                        <hr />
                        <Elements stripe={stripePromise}>
                            <CardForm userId={userId} onCardAdded={fetchPaymentMethods} />
                        </Elements>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={handleWaitForDriver} disabled={!selectedPaymentMethodId}>
                    {isSubmitted
                        ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;Wait for Driver...</>
                        : "Wait for Driver"
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default withAuth(CreditCardModal);
