import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import { API_URL } from '../../constants';
import {Link, useNavigate, useParams} from 'react-router-dom';
import withAuth from "../../auth";
import { Breadcrumb } from 'react-bootstrap';
import moment from "moment";
import {AuthContext} from "../AuthContext";
import Spinner from "react-bootstrap/Spinner";

const VehicleDetails = () => {
    const {vehicleId} = useParams();
    const [vehicle, setVehicle] = useState(null);
    const navigate = useNavigate();
    const {token, setToken} = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState('');
    const [formData, setFormData] = useState({
        year: '',
        make: '',
        model: '',
        color: '',
        box_or_deck_length: '',
        cargo_insurance: '',
        plate_number: '',
        truck_type_id: 0,
        available_to_deliver: 1,
    });
    const [isSubmitted, setIsSubmitted] = useState(false);

    // Fetch the details of the vehicle
    useEffect(() => {
        setErrorMessage('');
        axios.get(`${API_URL}/api/admin/vehicles/${vehicleId}`, {
                headers: {'Authorization': `Bearer ${token}`}
            })
            .then(response => {
                setVehicle(response.data.data);
            })
            .catch(error => {
                console.error('There was an error fetching the vehicle details!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }

                if (error?.response?.data?.errors) {
                    const errors = error.response.data.errors;
                    const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                    setErrorMessage(errorMessages);
                } else if (error?.response?.data?.message) {
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage('An error occurred. Please try again later.');
                }
            })
    }, [vehicleId]);

    useEffect(() => {
        if (vehicle) {
            setFormData({
                year: vehicle.year || '',
                make: vehicle.make || '',
                model: vehicle.model || '',
                color: vehicle.color || '',
                box_or_deck_length: vehicle.box_or_deck_length || '',
                cargo_insurance: vehicle.cargo_insurance || '',
                plate_number: vehicle.plate_number || '',
                truck_type_id: vehicle.truckType?.id || '',
                available_to_deliver: vehicle.available_to_deliver || 0,
                username: vehicle.username || '',
            });
        }
    }, [vehicle]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSave = async () => {
        if (!window.confirm("Are you sure that you want to update the vehicle?")) return;

        setErrorMessage('');
        setIsSubmitted(true);
        try {
            const response = await axios.put(`${API_URL}/api/admin/vehicles/${vehicleId}`, formData, {
                headers: {'Authorization': `Bearer ${token}`}
            });
            alert(response.data.message);
            setVehicle(response.data.vehicle);
        }
        catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setErrorMessage(errorMessages);
            } else if (error?.response?.data?.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('An error occurred. Please try again later.');
            }
        }
        finally {
            setIsSubmitted(false);
        }
    };

    return (
        <div className="container-fluid mt-3">
            <Breadcrumb>
                <Breadcrumb.Item href="/vehicles">Vehicle List</Breadcrumb.Item>
                <Breadcrumb.Item active>Vehicle Details</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="text-center">Vehicle Details {vehicle ? vehicle.id : ''}</h1>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            {vehicle ? (
                <div className="row">
                    <div className="col-md-6">
                        <div className="card mb-3">
                            <div className="card-body" align={'center'}>
                                {vehicle.image_url &&
                                    <a href={vehicle.image_url} target="_blank" rel="noopener noreferrer">
                                        <img src={vehicle.image_url} width="100" alt="Vehicle photo" className="mb-3" />
                                    </a>
                                }
                                {vehicle.company && (
                                    <div className="mb-2">
                                        <h5 className="card-title mb-1">Company</h5>
                                        <p className="card-text">Business Name: <b>{vehicle.company.business_name}</b></p>
                                        <hr/>
                                    </div>
                                )}
                                {vehicle.driver && (
                                    <div>
                                        <h5 className="card-title mb-1">Driver</h5>
                                        <p className="card-text">
                                            ID:&nbsp;
                                            <Link to={`/drivers/${vehicle.driver.id}`}>
                                                 <b>{vehicle.driver.id}</b>
                                            </Link><br/>
                                            Email: <b>{vehicle.driver.email}</b><br/>
                                            Name: <b>{vehicle.driver.name}</b>
                                        </p>
                                        <hr/>
                                    </div>
                                )}
                                <p className="card-text">
                                    Created At: <b>{moment(vehicle.created_at).format('YYYY-MM-DD HH:mm')}</b>
                                    <br/>
                                    Updated At: <b>{vehicle.updated_at ? moment(vehicle.updated_at).format('YYYY-MM-DD HH:mm') : 'unknown '}</b>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card mb-3">
                            <div className="card-body">
                                <h5 className="text-center">Details</h5>
                                {vehicle.company && (
                                    <>
                                        <div className="form-group mb-2">
                                            <label>Username:</label>
                                            <input type="text" className="form-control" name="username" value={formData.username} onChange={handleChange}/>
                                        </div>
                                        <div className="form-group mb-2">
                                            <label>New password:</label>
                                            <input type="password" className="form-control" name="password"
                                                   value={formData.password} onChange={handleChange}
                                                   autoComplete="new-password"/>
                                        </div>
                                        <hr/>
                                    </>
                                )}
                                <div className="form-group row mb-2">
                                    <label className="col-sm-3 col-form-label">Year:</label>
                                    <div className="col-sm-9">
                                        <input type="number" className="form-control" name="year" value={formData.year} onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="form-group row mb-2">
                                    <label className="col-sm-3 col-form-label">Make:</label>
                                    <div className="col-sm-9">
                                        <input type="text" className="form-control" name="make" value={formData.make} onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="form-group row mb-2">
                                    <label className="col-sm-3 col-form-label">Model:</label>
                                    <div className="col-sm-9">
                                        <input type="text" className="form-control" name="model" value={formData.model} onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="form-group row mb-2">
                                    <label className="col-sm-3 col-form-label">Color:</label>
                                    <div className="col-sm-9">
                                        <input type="text" className="form-control" name="color" value={formData.color} onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="form-group row mb-2">
                                    <label className="col-sm-3 col-form-label">Plate Number:</label>
                                    <div className="col-sm-9">
                                        <input type="text" className="form-control" name="plate_number" value={formData.plate_number} onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="form-group row mb-2">
                                    <label className="col-sm-3 col-form-label">Box or Deck Length:</label>
                                    <div className="col-sm-9">
                                        <input type="text" className="form-control" name="box_or_deck_length" value={formData.box_or_deck_length} onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="form-group row mb-2">
                                    <label className="col-sm-3 col-form-label">Cargo Insurance ($):</label>
                                    <div className="col-sm-9">
                                        <input type="text" className="form-control" name="cargo_insurance" value={formData.cargo_insurance} onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="form-group row mb-2">
                                    <label className="col-sm-3 col-form-label">Available to deliver:</label>
                                    <div className="col-sm-9">
                                        <select className="custom-select mr-sm-2" name="available_to_deliver" value={formData.available_to_deliver} onChange={handleChange}>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row mb-2">
                                    <label className="col-sm-3 col-form-label">Truck Type:</label>
                                    <div className="col-sm-9">
                                        <select className="custom-select mr-sm-2" name="truck_type_id" value={formData.truck_type_id} onChange={handleChange}>
                                            <option value="1">Pickup truck</option>
                                            <option value="2">Flatbed truck</option>
                                            <option value="3">Cube Van</option>
                                            <option value="4">Cargo Van</option>
                                        </select>
                                    </div>
                                </div>
                                {vehicle.insurance_photo &&
                                    <a href={vehicle.insurance_photo} target="_blank" rel="noopener noreferrer">
                                        <img src={vehicle.insurance_photo} width="100" alt="Vehicle photo" className="mb-3" />
                                    </a>
                                }
                                <div className="d-flex justify-content-center">
                                    <button className="btn btn-primary" onClick={handleSave}>
                                        {isSubmitted
                                            ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;Saving...</>
                                            : "Save"
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default withAuth(VehicleDetails);
