import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../AuthContext";
import {Link, useNavigate} from "react-router-dom";
import {API_URL} from "../../constants";
import axios from "axios";
import moment from "moment";
import withAuth from "../../auth";
import Pagination from "../Pagination";

const Coworkers = () => {
    const navigate = useNavigate();
    const {token, setToken} = useContext(AuthContext);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [error, setError] = useState("");

    useEffect(() => {
        setLoading(true);
        const fetchCoworkers = async () => {
            try {
                const {data} = await axios.get(`${API_URL}/api/admin/admins`, {headers: {Authorization: `Bearer ${token}`}});
                setUsers(data.data);
                setTotalPages(data.last_page);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem("authToken");
                    setToken(null);
                    navigate("/");
                }
                if (error?.response?.data?.errors) {
                    const errors = error.response.data.errors;
                    const errorMessages = Object.keys(errors)
                        .map((key) => errors[key].join(" "))
                        .join(" ");
                    setError(errorMessages);
                } else if (error?.response?.data?.message) {
                    setError(error.response.data.message);
                } else {
                    setError("An error occurred. Please try again later.");
                }
            } finally {
                setLoading(false);
            }
        };

        fetchCoworkers();
    }, [token, navigate]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className="container-fluid mt-3">
            <h2 className="text-center">Coworker List</h2>
            {error && <div className="alert alert-danger">{error}</div>}
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{height: "80vh"}}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <>
                <div className="table-responsive">
                    <table className="table table-striped text-center">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Roles</th>
                            <th>Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {users.map((user, idx) => (
                            <tr key={idx}>
                                <td>
                                    <Link to={`/coworkers/${user.id}`}>{user.id}</Link>
                                </td>
                                <td>{user.name && user.name}</td>
                                <td>{user.email && user.email}</td>
                                <td>
                                    {user.roles && user.roles.map((role, idx) => (
                                        <Link key={idx} to={`/coworkers/roles/${role.id}`} className="mr-1">
                                            {role.name}
                                        </Link>
                                    ))}
                                </td>
                                <td><span title="Created At">{moment(user.created_at).format("YYYY-MM-DD HH:mm")}</span></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                {totalPages > 1 && currentPage < totalPages && (
                    <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange}/>
                )}
                </>
            )}
        </div>
    );
};

export default withAuth(Coworkers);
