import {Link, useNavigate, useParams} from "react-router-dom";
import {Breadcrumb} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../AuthContext";
import axios from "axios";
import {API_URL} from "../../constants";
import withAuth from "../../auth";
import CoworkerAssignModal from "./CoworkerAssignModal";

const CoworkerProfile = () => {
    const {coworkerId} = useParams();
    const [coworker, setCoworker] = useState(null);
    const navigate = useNavigate();
    const {token, setToken} = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({name: '', email: '', password: ''});
    const [showModal, setShowModal] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        setLoading(true);
        const fetchTruckTypes = async () => {
            try {
                const {data} = await axios.get(`${API_URL}/api/admin/admins/${coworkerId}`, {headers: {Authorization: `Bearer ${token}`}});
                setCoworker(data);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem("authToken");
                    setToken(null);
                    navigate("/");
                }
                if (error?.response?.data?.errors) {
                    const errors = error.response.data.errors;
                    const errorMessages = Object.keys(errors)
                        .map((key) => errors[key].join(" "))
                        .join(" ");
                    setError(errorMessages);
                } else if (error?.response?.data?.message) {
                    setError(error.response.data.message);
                } else {
                    setError("An error occurred. Please try again later.");
                }
            } finally {
                setLoading(false);
            }
        };

        fetchTruckTypes();

    }, [coworkerId, token]);

    useEffect(() => {
        if (coworker) {
            setFormData({
                name: coworker.name || '',
                email: coworker.email || '',
            });
        }
    }, [coworker]);

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSave = async () => {
        if (!window.confirm("Are you sure that you want to update the coworker's data?")) return;

        setSuccess('');
        setError('');
        setIsSubmitted(true);
        try {
            const response = await axios.put(`${API_URL}/api/admin/admins/${coworkerId}`, formData, {
                headers: {'Authorization': `Bearer ${token}`}
            });
            setSuccess(response.data.message);
            setCoworker(response.data.data);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setError(errorMessages);
            } else if (error?.response?.data?.message) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred. Please try again later.');
            }
        } finally {
            setIsSubmitted(false);
        }
    };

    const handleDeleteClick = () => {
        setSuccess('');
        setError('');

        if (!window.confirm("Are you sure that you want to delete this coworker?")) return;

        axios.delete(`${API_URL}/api/admin/admins/${coworkerId}`, {
            headers: {'Authorization': `Bearer ${token}`}
        })
            .then(response => {
                alert('Coworker deleted successfully!');
                setCoworker(null);
                navigate('/coworkers');
            })
            .catch(error => {
                console.error('There was an error deleting the coworker!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
            });
    };

    const deleteRoleClick = (roleId, name, guard_name) => {
        setSuccess('');
        setError('');

        if (!window.confirm("Are you sure that you want to delete this role?")) return;

        axios.post(`${API_URL}/api/admin/admins/${coworkerId}/remove-role`, {role: name, guard_name}, {
            headers: {'Authorization': `Bearer ${token}`}
        })
            .then(response => {
                setSuccess('Role deleted successfully!');
                if (coworker && coworker.roles) {
                    const updatedRoles = coworker.roles.filter(role => role.id !== roleId);
                    setCoworker({ ...coworker, roles: updatedRoles });
                }
            })
            .catch(error => {
                console.error('There was an error deleting the role!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
            });
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const handleModalSuccess = (msg, coworker) => {
        setError('');
        setSuccess(msg);
        setCoworker(coworker);
    };

    return (
        <div className="container-fluid mt-3">
            <Breadcrumb>
                <Breadcrumb.Item href="/coworkers">Coworkers</Breadcrumb.Item>
                <Breadcrumb.Item active>Coworker Profile {coworkerId}</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="text-center">Coworker Profile {coworkerId}</h1>
            {success && <div className="alert alert-success">{success}</div>}
            {error && <div className="alert alert-danger">{error}</div>}
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{height: '80vh'}}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                ) :
                <div className="row">
                    <div className="col-lg-6">
                        <div className="card mb-3">
                            <h2 className='text-center mt-1'>Edit</h2>
                            <div className="card-body" align={'center'}>
                                <div className="form-group row mb-2">
                                    <label className="col-md-3 col-form-label">Name:</label>
                                    <div className="col-md-9">
                                        <input type="text" className="form-control" name="name" value={formData.name}
                                               onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="form-group row mb-2">
                                    <label className="col-md-3 col-form-label">Email:</label>
                                    <div className="col-md-9">
                                        <input type="text" className="form-control" name="email" value={formData.email}
                                               onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="form-group row mb-2">
                                    <label className="col-md-3 col-form-label">New password:</label>
                                    <div className="col-md-9">
                                        <input type="password" className="form-control" name="password"
                                               autoComplete="new-password" value={formData.password || ''}
                                               onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <button className="btn btn-primary" onClick={handleSave}>
                                        {isSubmitted
                                            ? (<><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>&nbsp;Saving...</>)
                                            : "Save"
                                        }
                                    </button>
                                </div>
                                <hr/>
                                <button className="btn btn-danger" onClick={handleDeleteClick}>Delete</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className='table-responsive card'>
                            <h2 className="text-center mt-1">Roles</h2>
                            <div className='card-body' align={'start'}>
                                <button className='btn btn-success mb-3' onClick={toggleModal}>Assign</button>
                                <CoworkerAssignModal adminId={coworkerId} showModal={showModal} handleClose={toggleModal} handleSuccess={handleModalSuccess}/>

                                <div className="table-responsive">
                                    <table className="table table-striped text-center ">
                                        <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Guard name</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {coworker?.roles.length ? coworker.roles.map((role, idx) => (
                                                <tr key={idx}>
                                                    <td><Link to={`/coworkers/roles/${role.id}`}>{role.name}</Link></td>
                                                    <td>{role.guard_name}</td>
                                                    <td>
                                                        <button className="btn btn-danger"
                                                                onClick={() => deleteRoleClick(role.id, role.name, role.guard_name)}>
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            )) :
                                            <tr>
                                                <td colSpan='3'><h5 align='center'>No assigned roles yet</h5></td>
                                            </tr>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default withAuth(CoworkerProfile);
