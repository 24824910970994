import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../AuthContext";
import {Link, useNavigate} from "react-router-dom";
import {API_URL} from "../../constants";
import axios from "axios";
import moment from "moment";
import withAuth from "../../auth";

const Roles = () => {
    const navigate = useNavigate();
    const {token, setToken} = useContext(AuthContext);
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        setLoading(true);
        const fetchRoles = async () => {
            try {
                const {data} = await axios.get(`${API_URL}/api/admin/roles`, {headers: {Authorization: `Bearer ${token}`}});
                setRoles(data.data);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem("authToken");
                    setToken(null);
                    navigate("/");
                }
                if (error?.response?.data?.errors) {
                    const errors = error.response.data.errors;
                    const errorMessages = Object.keys(errors)
                        .map((key) => errors[key].join(" "))
                        .join(" ");
                    setError(errorMessages);
                } else if (error?.response?.data?.message) {
                    setError(error.response.data.message);
                } else {
                    setError("An error occurred. Please try again later.");
                }
            } finally {
                setLoading(false);
            }
        };

        fetchRoles();
    }, [token, navigate]);

    return (
        <div className="container-fluid mt-3">
            <h2 className="text-center">Role List</h2>
            {error && <div className="alert alert-danger">{error}</div>}
            {loading ? (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{height: "80vh"}}
                >
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <div className="table-responsive">
                    <table className="table table-striped text-center">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Guard name</th>
                            <th>Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {roles.map((role, idx) => (
                            <tr key={idx}>
                                <td>
                                    <Link to={`/coworkers/roles/${role.id}`}>{role.id}</Link>
                                </td>
                                <td>{role.name && role.name}</td>
                                <td>{role.guard_name && role.guard_name}</td>
                                <td><span title="Created At">{moment(role.created_at).format("YYYY-MM-DD HH:mm")}</span></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default withAuth(Roles);
