import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../constants';
import withAuth from '../auth';
import {AuthContext} from "./AuthContext";
import Spinner from "react-bootstrap/Spinner";

const MyProfile = () => {
    const [loading, setLoading] = useState(true);
    const [newName, setNewName] = useState('');
    const [isNameSubmitted, setIsNameSubmitted] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [isPasswordSubmitted, setIsPasswordSubmitted] = useState(false);
    const [isAvatarSubmitted, setIsAvatarSubmitted] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { token, setToken, admin, setAdmin } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        axios.get(`${API_URL}/api/admin/account`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                setAdmin(response.data);
                setNewName(response.data?.name);
            })
            .catch(error => {
                console.error('There was an error fetching the admin details!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [navigate, setToken, token]);

    const handleChangeName = () => {
        if (!window.confirm("Are you sure that you want to change your name?")) return false;

        setErrorMessage('');
        setIsNameSubmitted(true);
        axios.put(`${API_URL}/api/admin/change-name`, { name: newName }, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                alert('Name changed successfully!');
                setAdmin({ ...admin, name: newName });
            })
            .catch(error => {
                console.error('Error changing name:', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
                else if (error?.response?.data?.errors) {
                    const errors = error.response.data.errors;
                    const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                    setErrorMessage(errorMessages);
                } else if (error?.response?.data?.message) {
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage('An error occurred. Please try again later.');
                }
            })
            .finally(() => {
                setIsNameSubmitted(false);
            });
    };

    const handleChangePassword = () => {
        if (!window.confirm("Are you sure that you want to change your password?")) return false;

        setErrorMessage('');
        setIsPasswordSubmitted(true);
        axios.put(`${API_URL}/api/admin/change-password`, { current_password: currentPassword, new_password: newPassword }, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                alert('Password changed successfully!');
                setCurrentPassword('');
                setNewPassword('');
            })
            .catch(error => {
                console.error('Error changing password:', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
                else if (error?.response?.data?.errors) {
                    const errors = error.response.data.errors;
                    const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                    setErrorMessage(errorMessages);
                } else if (error?.response?.data?.message) {
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage('An error occurred. Please try again later.');
                }
            })
            .finally(() => {
                setIsPasswordSubmitted(false);
            });
    };

    const getInitials = (name) => {
        return name.split(' ')
            .map(part => part[0])
            .join('')
            .toUpperCase();
    };

    const handleAvatarChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('avatar', file);

            setSuccessMessage('');
            setErrorMessage('');
            setIsAvatarSubmitted(true);
            axios.post(`${API_URL}/api/admin/account/upload-avatar`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                }
            })
                .then(response => {
                    setSuccessMessage('Avatar updated successfully!');
                    setTimeout(() => setSuccessMessage(''), 2000);
                    setAdmin({ ...admin, avatar: response.data.avatar });
                })
                .catch(error => {
                    console.error('Error uploading avatar:', error);
                    if (error.response && error.response.status === 401) {
                        localStorage.removeItem('authToken');
                        setToken(null);
                        navigate('/');
                    }
                    else if (error?.response?.data?.errors) {
                        const errors = error.response.data.errors;
                        const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                        setErrorMessage(errorMessages);
                    } else if (error?.response?.data?.message) {
                        setErrorMessage(error.response.data.message);
                    } else {
                        setErrorMessage('An error occurred. Please try again later.');
                    }
                })
                .finally(() => {
                    setIsAvatarSubmitted(false);
                });
        }
    };

    return (
        <div className="container mt-5">
            <h1 className="text-center mb-4">Admin Profile</h1>
            {successMessage && <div className="alert alert-success">{successMessage}</div>}
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            <div className="row">
                <div className="col-md-6">
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center my-4">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        admin && (
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Profile Details</h5>

                                    <div className="input-group mb-2">
                                        <input className="form-control" type="text" value={newName}
                                               onChange={(e) => setNewName(e.target.value)} />
                                        <span className="input-group-btn">
                                            <button className="btn btn-primary" onClick={handleChangeName}>
                                                {isNameSubmitted
                                                    ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                    : <i className="fa fa-edit" />
                                                }
                                            </button>
                                        </span>
                                    </div>

                                    <p>
                                        ID: <b>{admin.id}</b><br />
                                        Email: <b>{admin.email}</b>
                                    </p>

                                    <div align='center'>
                                        {admin.avatar ? (
                                            <img src={admin.avatar} alt={`${admin.name}'s Avatar`} width='150' />
                                        ) : (
                                            <div className="default-avatar" style={{width: '150px', height: '150px', fontSize: '40px'}}>
                                                {getInitials(admin.name)}
                                            </div>
                                        )}
                                    </div>

                                    <div className="mb-2">
                                        <label htmlFor="avatarInput" className="form-label">
                                            {isAvatarSubmitted
                                                ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;Uploading New Avatar...</>
                                                : "Upload New Avatar"
                                            }
                                        </label>
                                        <input type="file" className="form-control" id="avatarInput" onChange={handleAvatarChange} />
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </div>
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Change Password</h5>
                            <div className="mb-3">
                                <div className="form-group">
                                    <label className="form-label mb-0">Current Password</label>
                                    <input type="password" className="form-control" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
                                </div>
                                <div className="form-group mb-2">
                                    <label className="form-label mb-0">New Password</label>
                                    <input type="password" className="form-control" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                </div>
                                <button className="btn btn-primary" onClick={handleChangePassword}>
                                    {isPasswordSubmitted
                                        ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;Change...</>
                                        : "Change"
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withAuth(MyProfile);
