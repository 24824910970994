import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../constants';
import {AuthContext} from "../AuthContext";
import withAuth from "../../auth";
import Spinner from "react-bootstrap/Spinner";
import { Breadcrumb } from 'react-bootstrap';

const SendNotification = () => {
    const [notificationTitle, setNotificationTitle] = useState('');
    const [notificationBody, setNotificationBody] = useState('');
    const [isNotificationSent, setIsNotificationSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { token, setToken } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleSendPushNotification = () => {
        if (!window.confirm("Are you sure that you want to send the push notification to all drivers?")) return false;

        setErrorMessage('');
        setIsNotificationSent(true);
        axios.post(`${API_URL}/api/admin/drivers/notify-all`, {title: notificationTitle, body: notificationBody}, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                alert('Push notification successfully sent!');
                setNotificationTitle('');
                setNotificationBody('');
            })
            .catch(error => {
                console.error('There was an error deleting the driver!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }

                if (error?.response?.data?.errors) {
                    const errors = error.response.data.errors;
                    const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                    setErrorMessage(errorMessages);
                } else if (error?.response?.data?.message) {
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage('An error occurred. Please try again later.');
                }
            })
            .finally(() => {
                setIsNotificationSent(false);
            });
    };

    return (
        <>
            <div className="container-fluid mt-3">
                <Breadcrumb>
                    <Breadcrumb.Item href="/drivers">Drivers</Breadcrumb.Item>
                    <Breadcrumb.Item active>Notify All</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="container">
                <h1 className="text-center mb-4">Notify All</h1>
                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                <div className="card mb-3">
                    <div className="card-body">
                        <h5 className="text-center">Send Push Notification to all drivers</h5>
                        <div className="form-group mb-2">
                            <input className="form-control" type="text" placeholder="Title" value={notificationTitle}
                                   onChange={(e) => setNotificationTitle(e.target.value)} />
                        </div>
                        <div className="form-group mb-2">
                            <textarea className="form-control" style={{minHeight: '50px'}} placeholder="Body" value={notificationBody}
                                      onChange={(e) => setNotificationBody(e.target.value)} />
                        </div>
                        <div align={'center'}>
                            <button className="btn btn-primary" onClick={handleSendPushNotification}>
                                {isNotificationSent
                                    ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;Sending to all drivers...</>
                                    : 'Send to all drivers'
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withAuth(SendNotification);
