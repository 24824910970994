import React, {useState, useEffect, useContext} from "react";
import axios from "axios";
import Pagination from "./Pagination";
import moment from 'moment';
import {API_URL} from '../constants';
import {useNavigate} from "react-router-dom";
import {AuthContext} from "./AuthContext";
import withAuth from "../auth";

const AdminLogs = () => {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [sortBy, setSortBy] = useState('id');
    const [sortOrder, setSortOrder] = useState('desc');
    const [filters, setFilters] = useState({
        // admin_id
        // type_id
        // created_at_start_date
        // created_at_end_date
    });
    const navigate = useNavigate();
    const {token, setToken} = useContext(AuthContext);

    useEffect(() => {
        fetchLogs();
    }, [currentPage, sortBy, sortOrder]);

    const fetchLogs = async (newFilters = null) => {
        setLoading(true);
        try {
            const response = await axios.post(`${API_URL}/api/admin/admin-logs`,
                {
                    sort_by: sortBy,
                    sort_order: sortOrder,
                    page: currentPage,
                    ...(newFilters || filters),
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            setLogs(response.data.data);
            setTotalPages(response.data.last_page);
        } catch (error) {
            console.error("There was an error fetching the drivers!", error);
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSortChange = (column) => {
        if (sortBy === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(column);
            setSortOrder('asc');
        }
        setCurrentPage(1); // reset pagination when sort
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleFilterChange = (e, column) => {
        const newFilters = {
            ...filters,
            [column]: e.target.value,
        };

        const nonEmptyFilters = Object.keys(newFilters).reduce((acc, key) => {
            if (newFilters[key]) {
                acc[key] = newFilters[key];
            }
            return acc;
        }, {});

        setFilters(nonEmptyFilters);
    };
    const handleSearchSubmit = () => {
        setCurrentPage(1); // reset pagination when filter
        fetchLogs();
    };

    const resetFilters = () => {
        setFilters({});
        setCurrentPage(1);
        fetchLogs({});
    };

    const areFiltersEmpty = () => {
        return Object.values(filters).every(value => !value);
    };

    return (
        <div className="container-fluid mt-3">
            <h2 className="text-center">Admin Logs</h2>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <>
                    {!areFiltersEmpty() && (
                        <div className="d-flex justify-content-center mb-2">
                            <a onClick={() => resetFilters()} className="btn btn-warning">Reset Filters</a>
                        </div>
                    )}

                    <div className="table-responsive">
                        <table className="table table-sm table-striped text-center">
                            <thead>
                            <tr>
                                <th onClick={() => handleSortChange('admin_id')}>
                                    Coworker <i className={`fa fa-sort${sortBy === 'admin_id' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                                </th>
                                <th onClick={() => handleSortChange('type_id')}>
                                    Type <i className={`fa fa-sort${sortBy === 'type_id' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                                </th>
                                <th onClick={() => handleSortChange('created_at')}>
                                    Created At <i className={`fa fa-sort${sortBy === 'created_at' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                                </th>
                            </tr>
                            <tr>
                                {/* Coworker */}
                                <td>
                                    <div className="input-group justify-content-center">
                                        <input
                                            className="form-control form-control-sm text-center"
                                            style={{maxWidth: '300px'}}
                                            type="text"
                                            inputMode="search"
                                            value={filters.admin_id}
                                            placeholder="Coworker ID"
                                            onChange={(e) => handleFilterChange(e, 'admin_id')}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') handleSearchSubmit(e)
                                            }}
                                        />
                                        <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                            <i className="fa fa-search" />
                                        </button>
                                    </div>
                                </td>
                                {/* Type */}
                                <td>
                                    <div className="input-group justify-content-center">
                                        <input
                                            className="form-control form-control-sm text-center"
                                            style={{maxWidth: '300px'}}
                                            type="text"
                                            inputMode="search"
                                            value={filters.type_id}
                                            placeholder="Type ID"
                                            onChange={(e) => handleFilterChange(e, 'type_id')}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') handleSearchSubmit(e)
                                            }}
                                        />
                                        <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                            <i className="fa fa-search" />
                                        </button>
                                    </div>
                                </td>
                                {/* Created At */}
                                <td>
                                    <div className="input-group justify-content-center">
                                        <input type="datetime-local" className="form-control form-control-sm text-center"
                                               style={{maxWidth: '70px'}}
                                               value={filters.created_at_start_date}
                                               onChange={(e) => handleFilterChange(e, 'created_at_start_date')}
                                               onKeyPress={e => {
                                                   if (e.key === 'Enter') handleSearchSubmit(e)
                                               }}
                                        />
                                        <input type="datetime-local"
                                               className="form-control form-control-sm text-center"
                                               style={{maxWidth: '70px'}}
                                               value={filters.created_at_end_date}
                                               onChange={(e) => handleFilterChange(e, 'created_at_end_date')}
                                               onKeyPress={e => {
                                                   if (e.key === 'Enter') handleSearchSubmit(e)
                                               }}
                                        />
                                        <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                            <i className="fa fa-search" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            </thead>
                            <tbody>
                            {logs.map((log) => (
                                <tr key={log.id}>
                                    {/* Coworker */}
                                    <td>
                                        {log?.admin?.avatar && <div><img src={log.admin.avatar} alt="Profile" height={50} /></div> }
                                        #{log?.admin?.id} {log?.admin?.name}
                                    </td>
                                    {/* Type */}
                                    <td>
                                        #{log.type_id} {log.type_label} {log.additional_id > 0 && (<i>(ID: {log.additional_id})</i>)}
                                        <br/>
                                        <b><pre className="mb-0">{log.comment}</pre></b>
                                    </td>
                                    {/* Created At */}
                                    <td>{moment(log.created_at).format('YYYY-MM-DD HH:mm')}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        {totalPages > 1 && currentPage <= totalPages &&
                            <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange}/>
                        }
                    </div>
                </>
            )}
        </div>
    );
};

export default withAuth(AdminLogs);
