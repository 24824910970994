import React, {useState, useEffect, useContext} from "react";
import axios from "axios";
import withAuth from "../../auth";
import Pagination from "../Pagination";
import moment from 'moment';
import { API_URL } from '../../constants';
import {Link, useNavigate} from "react-router-dom";
import {AuthContext} from "../AuthContext";

const VehicleList = () => {
    const [vehicles, setVehicles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [sortBy, setSortBy] = useState('id');
    const [sortOrder, setSortOrder] = useState('desc');
    const [filters, setFilters] = useState({
        //'year', 'make', 'model', 'color', 'box_or_deck_length', 'cargo_insurance', 'plate_number', 'photo_filename', 'image_url'
        // 'id', 'company_id', 'driver_id', 'truck_type_id',
        // created_at_start_date, created_at_end_date
    });
    const navigate = useNavigate();
    const {token, setToken} = useContext(AuthContext);

    useEffect(() => {
        fetchVehicles();
    }, [currentPage, sortBy, sortOrder]);

    const fetchVehicles = async (newFilters = null) => {
        setLoading(true);
        try {
            const response = await axios.post(`${API_URL}/api/admin/vehicles`,
                {
                    sort_by: sortBy,
                    sort_order: sortOrder,
                    page: currentPage,
                    ...(newFilters || filters),
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            setVehicles(response.data.data);
            setTotalPages(response.data.last_page);
        } catch (error) {
            console.error("There was an error fetching the vehicles!", error);
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSortChange = (column) => {
        if (sortBy === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(column);
            setSortOrder('asc');
        }
        setCurrentPage(1); // reset pagination when sort
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleFilterChange = (e, column) => {
        const newFilters = {
            ...filters,
            [column]: e.target.value,
        };

        const nonEmptyFilters = Object.keys(newFilters).reduce((acc, key) => {
            if (newFilters[key]) {
                acc[key] = newFilters[key];
            }
            return acc;
        }, {});

        setFilters(nonEmptyFilters);
    };
    const handleSearchSubmit = () => {
        setCurrentPage(1); // reset pagination when filter
        fetchVehicles();
    };

    const handleDropdownChange = (e, column) => {
        const newFilters = {
            ...filters,
            [column]: e.target.value,
        };

        const nonEmptyFilters = Object.keys(newFilters).reduce((acc, key) => {
            if (newFilters[key]) {
                acc[key] = newFilters[key];
            }
            return acc;
        }, {});

        setFilters(nonEmptyFilters);
        setCurrentPage(1); // reset pagination when filter
        fetchVehicles(nonEmptyFilters);
    };

    const resetFilters = () => {
        setFilters({});
        setCurrentPage(1);
        fetchVehicles({});
    };

    const areFiltersEmpty = () => {
        return Object.values(filters).every(value => !value);
    };

    return (
        <div className="container-fluid mt-3">
            <h2 className="text-center">Vehicle List</h2>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <>
                    {!areFiltersEmpty() && (
                        <div className="d-flex justify-content-center mb-2">
                            <a onClick={() => resetFilters()} className="btn btn-warning">Reset Filters</a>
                        </div>
                    )}
                    <div className="table-responsive">
                    <table className="table table-sm table-striped text-center text-nowrap">
                        <thead>
                        <tr>
                            <th onClick={() => handleSortChange('id')}>
                                ID / Comp. <i className={`fa fa-sort${sortBy === 'id' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('driver_id')}>
                                Driver <i className={`fa fa-sort${sortBy === 'driver_id' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('username')}>
                                Username <i className={`fa fa-sort${sortBy === 'username' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('truck_type_id')}>
                                Type <i className={`fa fa-sort${sortBy === 'truck_type_id' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('year')}>
                                Year <i className={`fa fa-sort${sortBy === 'year' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('make')}>
                                Make <i className={`fa fa-sort${sortBy === 'make' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('model')}>
                                Model <i className={`fa fa-sort${sortBy === 'model' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('created_at')}>
                                Created At <i className={`fa fa-sort${sortBy === 'created_at' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                        </tr>
                        <tr>
                            {/* ID / Comp. */}
                            <td>
                                <div className="input-group">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.id}
                                        placeholder="ID"
                                        onChange={(e) => handleFilterChange(e, 'id')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            {/* Driver */}
                            <td>
                                <div className="input-group">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.driver_id}
                                        placeholder="Driver ID"
                                        onChange={(e) => handleFilterChange(e, 'driver_id')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            {/* Username */}
                            <td>
                                <div className="input-group">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.username}
                                        placeholder="Username"
                                        onChange={(e) => handleFilterChange(e, 'username')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            {/* Type */}
                            <td>
                                <div className="input-group">
                                    <select
                                        className="form-control form-control-sm text-center"
                                        value={filters.truck_type_id}
                                        onChange={(e) => handleDropdownChange(e, 'truck_type_id')}
                                    >
                                        <option value="">All</option>
                                        <option value="1">Pickup truck</option>
                                        <option value="2">Flatbed truck</option>
                                        <option value="3">Cube Van</option>
                                        <option value="4">Cargo Van</option>
                                    </select>
                                </div>
                            </td>
                            {/* Year */}
                            <td>
                                <div className="input-group">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.year}
                                        placeholder="Year"
                                        onChange={(e) => handleFilterChange(e, 'year')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            {/* Make */}
                            <td>
                                <div className="input-group">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.make}
                                        placeholder="Make"
                                        onChange={(e) => handleFilterChange(e, 'make')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            {/* Model */}
                            <td>
                                <div className="input-group">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.model}
                                        placeholder="Model"
                                        onChange={(e) => handleFilterChange(e, 'model')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            {/* Created At */}
                            <td></td>
                        </tr>
                        </thead>
                        <tbody className="text-wrap">
                        {vehicles.map((vehicle) => (
                            <tr key={vehicle.id}>
                                {/* ID / Company */}
                                <td>
                                    <Link to={`/vehicles/${vehicle.id}`}>
                                        {vehicle.id}
                                    </Link>
                                    <br/>
                                    {vehicle.company ? vehicle.company?.business_name : ''}
                                </td>
                                {/* Driver */}
                                <td>
                                    {vehicle?.driver?.id}<br/>
                                    {vehicle?.driver?.name}
                                </td>
                                {/* Username / Photo */}
                                <td>
                                    {vehicle.image_url && <div><img src={vehicle.image_url} alt="Profile" height={50} /></div> }
                                    {vehicle.username}
                                </td>
                                {/* Type */}
                                <td>{vehicle?.truckType?.title}</td>
                                {/* Year */}
                                <td>{vehicle.year}</td>
                                {/* Make */}
                                <td>{vehicle.make}</td>
                                {/* Model */}
                                <td>{vehicle.model}</td>
                                {/* Created At */}
                                <td>{moment(vehicle.created_at).format('YYYY-MM-DD HH:mm')}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {totalPages > 1 && currentPage <= totalPages &&
                        <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange}/>
                    }
                </div>
                </>
            )}
        </div>
    );
};

export default withAuth(VehicleList);
