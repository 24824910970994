import React, {useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {AuthContext} from "./components/AuthContext";

const withAuth = (Component) => {
    return (props) => {
        const navigate = useNavigate();
        const token = localStorage.getItem('authToken');
        const { setToken } = useContext(AuthContext);

        useEffect(() => {
            if (!token) {
                setToken(token);
                navigate('/');
            }
        }, [navigate, token]);

        if (!token) {
            return null;
        }

        return <Component {...props} />;
    };
};

export default withAuth;
