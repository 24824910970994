import React from 'react';

function Footer() {
    return (
        <footer className="mt-5 py-3 bg-light">
            <div className="container">
                <p className="m-0 text-center text-dark">&copy; {new Date().getFullYear()} Navipickups. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
