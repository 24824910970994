import {useNavigate, useParams} from "react-router-dom";
import {Breadcrumb} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../AuthContext";
import axios from "axios";
import {API_URL} from "../../constants";
import withAuth from "../../auth";

const PermissionDetails = () => {
    const {permissionId} = useParams();
    const [permission, setPermission] = useState(null);
    const navigate = useNavigate();
    const {token, setToken} = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [formData, setFormData] = useState({name: '', guard_name: 'admin'});
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        setLoading(true);
        const fetchTruckTypes = async () => {
            try {
                const {data} = await axios.get(
                    `${API_URL}/api/admin/permissions/${permissionId}`,

                    {headers: {Authorization: `Bearer ${token}`}}
                );
                setPermission(data.data);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem("authToken");
                    setToken(null);
                    navigate("/");
                }
                if (error?.response?.data?.errors) {
                    const errors = error.response.data.errors;
                    const errorMessages = Object.keys(errors)
                        .map((key) => errors[key].join(" "))
                        .join(" ");
                    setErrorMessage(errorMessages);
                } else if (error?.response?.data?.message) {
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage("An error occurred. Please try again later.");
                }
            } finally {
                setLoading(false);
            }
        };

        fetchTruckTypes();

    }, [permissionId, token]);

    useEffect(() => {
        if (permission) {
            setFormData({
                name: permission.name || '',
                guard_name: permission.guard_name || '',
            });
        }
    }, [permission]);

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSave = async () => {
        if (!window.confirm("Are you sure that you want to update the permission's data?")) return;

        setErrorMessage('');
        setIsSubmitted(true);
        try {
            const response = await axios.put(`${API_URL}/api/admin/permissions/${permissionId}`, formData, {
                headers: {'Authorization': `Bearer ${token}`}
            });
            alert(response.data.message);
            setPermission(response.data.permission);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setErrorMessage(errorMessages);
            } else if (error?.response?.data?.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('An error occurred. Please try again later.');
            }
        } finally {
            setIsSubmitted(false);
        }
    };

    const handleDeleteClick = () => {
        if (!window.confirm("Are you sure that you want to delete this permission?")) return;

        axios.delete(`${API_URL}/api/admin/permissions/${permissionId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                alert('Permission deleted successfully!');
                setPermission(null);
                navigate('/coworkers/permissions');
            })
            .catch(error => {
                console.error('There was an error deleting the permission!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
            });
    };

    return <div className="container-fluid mt-3">
        <Breadcrumb>
            <Breadcrumb.Item href="/coworkers/permissions">Permissions</Breadcrumb.Item>
            <Breadcrumb.Item active>Permission {permissionId}</Breadcrumb.Item>
        </Breadcrumb>
        <h1 className="text-center">Permission {permissionId}</h1>
        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        {loading ? (
            <div className="d-flex justify-content-center align-items-center" style={{height: '80vh'}}>
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        ) : (
            <div className="card mb-3">
                <div className="card-body" align={'center'}>

                    <div className="form-group row mb-2">
                        <label className="col-md-3 col-form-label">Name:</label>
                        <div className="col-md-9">
                            <input type="text" className="form-control" name="name" value={formData.name} onChange={handleChange}/>
                        </div>
                    </div>
                    <div className="form-group row mb-2">
                        <label className="col-md-3 col-form-label">Guard Name:</label>
                        <div className="col-md-9">
                            <input type="text" className="form-control" name="guard_name" value={formData.guard_name} onChange={handleChange}/>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center">
                        <button className="btn btn-primary" onClick={handleSave}>
                            {isSubmitted
                                ? <><Spinner as="span" animation="border" size="sm" role="status"
                                             aria-hidden="true"/>&nbsp;Saving...</>
                                : "Save"
                            }
                        </button>
                    </div>
                    <hr/>
                    <button className="btn btn-danger" onClick={handleDeleteClick}>Delete</button>
                </div>
            </div>
        )}
    </div>
};

export default withAuth(PermissionDetails)
