import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { API_URL } from '../../constants';
import { AuthContext } from "../AuthContext";
import Spinner from "react-bootstrap/Spinner";
import withAuth from "../../auth";
import {useNavigate} from "react-router-dom";

const CargoInsuranceList = () => {
    const [cargoInsurances, setCargoInsurances] = useState(null);
    const [updatingId, setUpdatingId] = useState(null);
    const {token, setToken, hasPermission} = useContext(AuthContext);
    const navigate = useNavigate();

    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchCargoInsurances = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/admin/cargo-insurances`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setCargoInsurances(response.data.data);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
                if (error?.response?.data?.errors) {
                    const errors = error.response.data.errors;
                    const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                    setError(errorMessages);
                } else if (error?.response?.data?.message) {
                    setError(error.response.data.message);
                } else {
                    setError('An error occurred. Please try again later.');
                }
            }
        };

        fetchCargoInsurances();
    }, [token]);

    const handleChange = (e, id) => {
        const { name, value } = e.target;
        setCargoInsurances(cargoInsurances.map(insurance =>
            insurance.id === id ? { ...insurance, [name]: value } : insurance
        ));
    };

    const handleUpdate = async (id) => {
        if (!window.confirm("Are you sure that you want to update the Cargo Insurance?")) return false;

        setSuccess('');
        setError('');
        setUpdatingId(id);
        const insuranceToUpdate = cargoInsurances.find(insurance => insurance.id === id);
        try {
            await axios.put(`${API_URL}/api/admin/cargo-insurances/${id}`, insuranceToUpdate, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setSuccess(`Cargo Insurance #${id} has been successfully updated`);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setError(errorMessages);
            } else if (error?.response?.data?.message) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred. Please try again later.');
            }
        } finally {
            setUpdatingId(null);
        }
    };

    return (
        <div className="container mt-5">
            <h1 className="text-center">Cargo Insurances</h1>
            {success && <div className="alert alert-success">{success}</div>}
            {error && <div className="alert alert-danger">{error}</div>}
            {cargoInsurances ? (
                <div className="table-responsive">
                    <table className="table text-center">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Insurance Range</th>
                            <th>Description</th>
                            <th>Cost</th>
                            {hasPermission("cargo-insurance-update") && <th>Action</th>}
                        </tr>
                        </thead>
                        <tbody>
                        {cargoInsurances.map((insurance) => (
                            <tr key={insurance.id}>
                                <td style={{verticalAlign: 'middle'}}>{insurance.id}</td>
                                <td style={{width: '20%'}}>
                                    {hasPermission("cargo-insurance-update") ? (
                                        <input
                                            type="text"
                                            name="insurance_range"
                                            value={insurance.insurance_range}
                                            onChange={(e) => handleChange(e, insurance.id)}
                                            className="form-control"
                                        />
                                    ) : insurance.insurance_range}
                                </td>
                                <td>
                                    {hasPermission("cargo-insurance-update") ? (
                                        <input
                                            type="text"
                                            name="description"
                                            value={insurance.description}
                                            onChange={(e) => handleChange(e, insurance.id)}
                                            className="form-control"
                                        />
                                    ) : insurance.description}
                                </td>
                                <td style={{width: '15%', minWidth: '90px'}}>
                                    {hasPermission("cargo-insurance-update") ? (
                                        <input
                                            type="number"
                                            name="cost"
                                            value={insurance.cost}
                                            onChange={(e) => handleChange(e, insurance.id)}
                                            step="0.01"
                                            className="form-control"
                                        />
                                    ) : insurance.cost}
                                </td>
                                {hasPermission("cargo-insurance-update") && (
                                    <td>
                                        <button className="btn btn-success" onClick={() => handleUpdate(insurance.id)} disabled={updatingId === insurance.id && updatingId !== null}>
                                            {updatingId === insurance.id ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Update'}
                                        </button>
                                    </td>
                                )}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default withAuth(CargoInsuranceList);
