import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import withAuth from "../../auth";
import Pagination from "../Pagination";
import moment from 'moment';
import { API_URL } from '../../constants';
import { Link, useNavigate } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { AuthContext } from "../AuthContext";

const PromoCodeList = () => {
    const [promoCodes, setPromoCodes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [sortBy, setSortBy] = useState('id');
    const [sortOrder, setSortOrder] = useState('desc');
    const [filters, setFilters] = useState({});
    const navigate = useNavigate();
    const { token, setToken } = useContext(AuthContext);

    useEffect(() => {
        fetchPromoCodes();
    }, [currentPage, sortBy, sortOrder]);

    const fetchPromoCodes = async (newFilters = null) => {
        setLoading(true);
        try {
            const response = await axios.post(`${API_URL}/api/admin/promo-codes`,
                {
                    sort_by: sortBy,
                    sort_order: sortOrder,
                    page: currentPage,
                    ...(newFilters || filters),
                },
                {headers: {'Authorization': `Bearer ${token}`}}
            );
            setPromoCodes(response.data.data);
            setTotalPages(response.data.last_page);
        } catch (error) {
            console.error("There was an error fetching the promoCodes!", error);
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSortChange = (column) => {
        if (sortBy === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(column);
            setSortOrder('asc');
        }
        setCurrentPage(1); // reset pagination when sort
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleFilterChange = (e, column) => {
        const newFilters = {
            ...filters,
            [column]: e.target.value,
        };

        const nonEmptyFilters = Object.keys(newFilters).reduce((acc, key) => {
            if (newFilters[key]) {
                acc[key] = newFilters[key];
            }
            return acc;
        }, {});

        setFilters(nonEmptyFilters);
    };

    const handleSearchSubmit = () => {
        setCurrentPage(1); // reset pagination when filter
        fetchPromoCodes();
    };

    const handleDropdownChange = (e, column) => {
        const newFilters = {
            ...filters,
            [column]: e.target.value,
        };

        const nonEmptyFilters = Object.keys(newFilters).reduce((acc, key) => {
            if (newFilters[key]) {
                acc[key] = newFilters[key];
            }
            return acc;
        }, {});

        setFilters(nonEmptyFilters);
        setCurrentPage(1); // reset pagination when filter
        fetchPromoCodes(nonEmptyFilters);
    };

    return (
        <div className="container-fluid mt-3">
            <h2 className="text-center">Promo Code List</h2>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <div className="table-responsive">
                    <table className="table table-sm table-striped text-center text-nowrap">
                        <thead>
                        <tr>
                            <th onClick={() => handleSortChange('id')}>
                                ID <i className={`fa fa-sort${sortBy === 'id' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`} />
                            </th>
                            <th onClick={() => handleSortChange('code')}>
                                Code <i className={`fa fa-sort${sortBy === 'code' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`} />
                            </th>
                            <th onClick={() => handleSortChange('discount_type')}>
                                Type <i className={`fa fa-sort${sortBy === 'discount_type' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`} />
                            </th>
                            <th onClick={() => handleSortChange('discount_value')}>
                                Value <i className={`fa fa-sort${sortBy === 'discount_value' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`} />
                            </th>
                            <th>Valid</th>
                            <th onClick={() => handleSortChange('times_used')}>
                                Usage <i className={`fa fa-sort${sortBy === 'times_used' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`} />
                            </th>
                            <th onClick={() => handleSortChange('is_active')}>
                                Active <i className={`fa fa-sort${sortBy === 'is_active' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`} />
                            </th>
                            <th onClick={() => handleSortChange('created_at')}>
                                Created <i className={`fa fa-sort${sortBy === 'created_at' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`} />
                            </th>
                        </tr>
                        <tr>
                            {/* ID */}
                            <td>
                                <div className="input-group">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.id}
                                        placeholder='ID'
                                        onChange={(e) => handleFilterChange(e, 'id')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            {/* Code */}
                            <td>
                                <div className="input-group">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.code}
                                        onChange={(e) => handleFilterChange(e, 'code')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            {/* Type */}
                            <td>
                                <div className="input-group">
                                    <select
                                        className="form-control form-control-sm text-center"
                                        value={filters.discount_type}
                                        onChange={(e) => handleDropdownChange(e, 'discount_type')}
                                    >
                                        <option value="">All</option>
                                        <option value="percentage">Percentage</option>
                                        <option value="fixed">Fixed</option>
                                    </select>
                                </div>
                            </td>
                            {/* Value */}
                            <td>
                                <div className="input-group" style={{ minWidth: '140px' }}>
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.discount_value}
                                        onChange={(e) => handleFilterChange(e, 'discount_value')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            {/* Valid */}
                            <td></td>
                            {/* Usage */}
                            <td></td>
                            {/* Active */}
                            <td>
                                <div className="input-group">
                                    <select
                                        className="form-control form-control-sm text-center"
                                        value={filters.is_active}
                                        onChange={(e) => handleDropdownChange(e, 'is_active')}
                                    >
                                        <option value="">All</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                </div>
                            </td>
                            {/* Created */}
                            <td>
                                <div className="input-group justify-content-center">
                                    <input type="datetime-local" className="form-control form-control-sm text-center"
                                           style={{maxWidth: '70px'}}
                                           value={filters.created_at_start_date}
                                           onChange={(e) => handleFilterChange(e, 'created_at_start_date')}
                                           onKeyPress={e => {
                                               if (e.key === 'Enter') handleSearchSubmit(e)
                                           }}
                                    />
                                    <input type="datetime-local"
                                           className="form-control form-control-sm text-center"
                                           style={{maxWidth: '70px'}}
                                           value={filters.created_at_end_date}
                                           onChange={(e) => handleFilterChange(e, 'created_at_end_date')}
                                           onKeyPress={e => {
                                               if (e.key === 'Enter') handleSearchSubmit(e)
                                           }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                        </thead>
                        <tbody className="text-wrap">
                        {promoCodes.map((promoCode) => (
                            <tr key={promoCode.id}>
                                <td className={promoCode.is_active ? '' : 'table-danger'}>
                                    <Link to={`/promo-codes/${promoCode.id}`}>
                                        {promoCode.id}
                                    </Link>
                                </td>
                                <td>{promoCode.code}</td>
                                <td>{promoCode.discount_type}</td>
                                <td>{promoCode.discount_type === 'percentage' ? promoCode.discount_value + '%' : '$' + promoCode.discount_value}</td>
                                <td>
                                    {moment(promoCode.valid_from).format('YYYY-MM-DD HH:mm')}
                                    <br/>
                                    {moment(promoCode.valid_to).format('YYYY-MM-DD HH:mm')}
                                </td>
                                <td>{promoCode.times_used}/{promoCode.usage_limit}</td>
                                <td>{promoCode.is_active ? 'Yes' : 'No'}</td>
                                <td>{moment(promoCode.created_at).format('YYYY-MM-DD HH:mm')}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {
                        totalPages > 1 && currentPage < totalPages && (
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                handlePageChange={handlePageChange}
                            />
                        )
                    }
                </div>
            )}
        </div>
    );
};

export default withAuth(PromoCodeList);
