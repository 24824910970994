import React, { useState, useContext } from 'react';
import axios from 'axios';
import { API_URL } from '../../constants';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import withAuth from '../../auth';
import Spinner from 'react-bootstrap/Spinner';
import NPPlacesInput from '../google_map/NPPlacesInput';
import NPMapViewDirections from '../google_map/NPMapViewDirections';
import { GoogleMapsProvider } from '../google_map/GoogleMapsContext';

const PickupCreate = () => {
    const { userId } = useParams();
    const [routes, setRoutes] = useState([{ origin: {}, dropoff: {} }]);
    const [distance, setDistance] = useState(0);
    const [truckType, setTruckType] = useState(1);
    const [cargoInsurance, setCargoInsurance] = useState(1);
    const [items, setItems] = useState([]);
    const [pickup, setPickup] = useState({ origins: [], dropoffs: [] });
    const [optimizedWaypoints, setOptimizedWaypoints] = useState([]);

    const { token, setToken } = useContext(AuthContext);
    const navigate = useNavigate();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState('');

    const handlePickupSelect = (index, address, location) => {
        const newRoutes = [...routes];
        newRoutes[index].origin = {
            ...newRoutes[index].origin,
            address,
            latitude: location.lat,
            longitude: location.lng
        };
        setRoutes(newRoutes);
        setPickup(prev => ({
            ...prev,
            origins: newRoutes.map(route => route.origin)
        }));
    };

    const handleDropoffSelect = (index, address, location) => {
        const newRoutes = [...routes];
        newRoutes[index].dropoff = {
            ...newRoutes[index].dropoff,
            address,
            latitude: location.lat,
            longitude: location.lng
        };
        setRoutes(newRoutes);
        setPickup(prev => ({
            ...prev,
            dropoffs: newRoutes.map(route => route.dropoff)
        }));
    };


    const handleAddRoute = () => {
        setRoutes([...routes, { origin: {}, dropoff: {} }]);
    };

    const handleRemoveRoute = (index) => {
        const newRoutes = routes.filter((_, i) => i !== index);
        setRoutes(newRoutes);

        // Update items to handle route removal
        const newItems = items.map(item => ({
            ...item,
            routeIndex: item.routeIndex > index ? item.routeIndex - 1 : item.routeIndex
        })).filter(item => item.routeIndex !== index);

        setItems(newItems);
        setPickup(prev => ({
            ...prev,
            origins: newRoutes.map(route => route.origin),
            dropoffs: newRoutes.map(route => route.dropoff)
        }));
    };

    const handleAddItem = () => {
        setItems([...items, { item_name: '', weight: '0-200Lbs', size: 'Small', details: '', routeIndex: 0 }]);
    };

    const handleRemoveItem = (index) => {
        setItems(items.filter((_, i) => i !== index));
    };
    const handleRouteChange = (index, field, value, isOrigin = true) => {
        const newRoutes = [...routes];

        if (isOrigin) {
            newRoutes[index].origin = {
                ...newRoutes[index].origin,
                [field]: value
            };
        } else {
            newRoutes[index].dropoff = {
                ...newRoutes[index].dropoff,
                [field]: value
            };
        }

        setRoutes(newRoutes);

        setPickup(prev => ({
            ...prev,
            origins: newRoutes.map(route => route.origin),
            dropoffs: newRoutes.map(route => route.dropoff)
        }));
    };

    const handleItemChange = (index, field, value) => {
        const newItems = [...items];
        newItems[index][field] = value;
        setItems(newItems);
    };

    const handleItemRouteChange = (index, routeIndex) => {
        const newItems = [...items];
        newItems[index].routeIndex = routeIndex;
        setItems(newItems);
    };

    const handleWaypointsOptimized = (waypoints) => {
        setOptimizedWaypoints(waypoints);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const requestPayload = {
            add_info: null,
            distance,
            origin_lat: pickup.origins[0]?.latitude,
            origin_long: pickup.origins[0]?.longitude,
            origin_address: pickup.origins[0]?.address,
            dropoff_lat: pickup.dropoffs[pickup.dropoffs.length - 1]?.latitude,
            dropoff_long: pickup.dropoffs[pickup.dropoffs.length - 1]?.longitude,
            dropoff_address: pickup.dropoffs[pickup.dropoffs.length - 1]?.address,
            truck_type_id: truckType,
            cargo_insurance_id: cargoInsurance,
            items: items.map((item, index) => ({
                item_name: item.item_name,
                size: item.size,
                weight: item.weight,
                photo: null,
                details: item.details,
                id: item.id || index.toString(),
                origin: {
                    latitude: routes[item.routeIndex].origin.latitude,
                    longitude: routes[item.routeIndex].origin.longitude,
                    description: routes[item.routeIndex].origin.address,
                    contact_name:  routes[item.routeIndex].origin.contact_name,
                    contact_phone: routes[item.routeIndex].origin.contact_phone,
                    address: routes[item.routeIndex].origin.address
                },
                dropoff: {
                    latitude: routes[item.routeIndex].dropoff.latitude,
                    longitude: routes[item.routeIndex].dropoff.longitude,
                    description: routes[item.routeIndex].dropoff.address,
                    contact_name: routes[item.routeIndex].dropoff.contact_name,
                    contact_phone: routes[item.routeIndex].dropoff.contact_phone,
                    address: routes[item.routeIndex].dropoff.address
                }
            })),
            origins: pickup.origins.map((origin) => ({
                latitude: origin.latitude,
                longitude: origin.longitude,
                description: origin.address,
                contact_name: origin.contact_name,
                contact_phone: origin.contact_phone,
                address: origin.address
            })),
            dropoffs: pickup.dropoffs.map((dropoff) => ({
                latitude: dropoff.latitude,
                longitude: dropoff.longitude,
                description: dropoff.address,
                contact_name: dropoff.contact_name,
                contact_phone: dropoff.contact_phone,
                address: dropoff.address
            })),
            optimizedWaypoints: optimizedWaypoints
        };

        setError('');
        setIsSubmitted(true);
        try {
            const response = await axios.post(`${API_URL}/api/admin/pickups/create/${userId}`, requestPayload, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (response?.data?.pickup?.id) {
                navigate(`/pickups/${response.data.pickup.id}`);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setError(errorMessages);
            } else if (error?.response?.data?.message) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred. Please try again later.');
            }
        } finally {
            setIsSubmitted(false);
        }
    };

    return (
        <GoogleMapsProvider>
            <div className="container-fluid mt-5">
                <h1 className="text-center">Create Pickup for Customer {userId}</h1>
                {error && <div className="alert alert-danger">{error}</div>}
                <div className="row">
                    <div className="col-xl-6">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    {routes.map((route, index) => (
                                        <div key={index} className="mb-3">
                                            <h5 className="d-flex justify-content-between">
                                                <span>Route {index + 1}</span>
                                                <button type="button"
                                                        className="btn btn-sm btn-outline-danger ml-2 px-2 py-1"
                                                        onClick={() => handleRemoveRoute(index)}>
                                                    <i className="fa fa-times"/>
                                                </button>
                                            </h5>
                                            <div className="mb-3">
                                                <NPPlacesInput
                                                    placeholder="Pickup Address"
                                                    value={route.origin.address || ''}
                                                    onResultSelected={(address, location) => handlePickupSelect(index, address, location)}
                                                    uniqueKey={`origin_address_${index}`}
                                                />

                                                <div className='d-flex mt-1'>
                                                    <input
                                                        type="text"
                                                        onChange={(e) => handleRouteChange(index, 'contact_name', e.target.value, true)}
                                                        placeholder="Contact name"
                                                        className="form-control w-50 mr-1"
                                                    />
                                                    <input
                                                        type="text"
                                                        onChange={(e) => handleRouteChange(index, 'contact_phone', e.target.value, true)}
                                                        placeholder="Contact phone"
                                                        className="form-control w-50"
                                                    />
                                                </div>
                                            </div>

                                            <NPPlacesInput
                                                placeholder="Drop-off Address"
                                                value={route.dropoff.address || ''}
                                                onResultSelected={(address, location) => handleDropoffSelect(index, address, location)}
                                                uniqueKey={`dropoff_address_${index}`}
                                            />

                                            <div className='d-flex mt-1'>
                                                <input
                                                    type="text"
                                                    onChange={(e) => handleRouteChange(index, 'contact_name', e.target.value, false)}
                                                    placeholder="Contact name"
                                                    className="form-control w-50 mr-1"
                                                />
                                                <input
                                                    type="text"
                                                    onChange={(e) => handleRouteChange(index, 'contact_phone', e.target.value, false)}
                                                    placeholder="Contact phone"
                                                    className="form-control w-50"
                                                />
                                            </div>

                                        </div>
                                    ))}
                                    <button type="button" className="btn btn-secondary mb-2"
                                            onClick={handleAddRoute}>Add Route
                                    </button>
                                    <div className="form-row mb-1">
                                        <label className="col-sm-3 col-form-label">Truck Type:</label>
                                        <div className="col-sm-9">
                                            <select className="custom-select" value={truckType}
                                                    onChange={(e) => setTruckType(e.target.value)}>
                                                <option>Select Truck Type</option>
                                                <option value="1">Pickup truck</option>
                                                <option value="2">Flatbed truck</option>
                                                <option value="3">Cube Van</option>
                                                <option value="4">Cargo Van</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-row mb-0">
                                        <label className="col-sm-3 col-form-label">Cargo Insurance:</label>
                                        <div className="col-sm-9">
                                            <select className="custom-select" value={cargoInsurance} onChange={(e) => setCargoInsurance(e.target.value)}>
                                                <option>Select Cargo Insurance</option>
                                                <option value="1">$0 - $499</option>
                                                <option value="2">$500 - $999</option>
                                                <option value="3">$1000 - $1999</option>
                                                <option value="4">$2000 - $2999</option>
                                                <option value="5">$3000 - $5000</option>
                                                <option value="6">N/A</option>
                                            </select>
                                        </div>
                                    </div>

                                    <hr />
                                    <h5 className="text-center">Items</h5>
                                    <div className="table-responsive">
                                        <table className="table table-sm table-borderless">
                                            <tbody>
                                            {items && items.map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                value={item.item_name}
                                                                onChange={(e) => handleItemChange(index, 'item_name', e.target.value)}
                                                                placeholder="Item Name"
                                                                className="form-control"
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                value={item.weight}
                                                                onChange={(e) => handleItemChange(index, 'weight', e.target.value)}
                                                                placeholder="Weight"
                                                                className="form-control"
                                                            />
                                                        </td>
                                                        <td>
                                                            <select
                                                                value={item.size}
                                                                onChange={(e) => handleItemChange(index, 'size', e.target.value)}
                                                                className="custom-select"
                                                            >
                                                                <option value="">Select Size</option>
                                                                <option value="Small">Small</option>
                                                                <option value="Medium">Medium</option>
                                                                <option value="Large">Large</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select
                                                                value={item.routeIndex}
                                                                onChange={(e) => handleItemRouteChange(index, parseInt(e.target.value))}
                                                                className="custom-select"
                                                            >
                                                                {routes.map((_, routeIndex) => (
                                                                    <option key={routeIndex}
                                                                            value={routeIndex}>Route {routeIndex + 1}</option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                        <td rowSpan="2">
                                                            <button type="button" className="btn btn-sm btn-danger mt-1"
                                                                    onClick={() => handleRemoveItem(index)}><i
                                                                className="fa fa-trash"/></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="4">
                                                            <input
                                                                type="text"
                                                                value={item.details}
                                                                onChange={(e) => handleItemChange(index, 'details', e.target.value)}
                                                                placeholder="Details"
                                                                className="form-control"
                                                            />
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td colSpan="5">
                                                            <hr className="my-0"/>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <button type="button" className="btn btn-warning mb-2" onClick={handleAddItem}>Add
                                        Item
                                    </button>
                                    <hr/>
                                    <div className="d-flex justify-content-center">
                                        <button type="submit" className="btn btn-primary">
                                            {isSubmitted
                                                ? <><Spinner as="span" animation="border" size="sm" role="status"
                                                             aria-hidden="true"/>&nbsp;Creating...</>
                                                : "Create"
                                            }
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="card">
                            <div className="card-body" style={{ minHeight: '400px' }}>
                                <NPMapViewDirections
                                    origins={pickup.origins}
                                    dropoffs={pickup.dropoffs}
                                    onDistanceCalculated={setDistance}
                                    onWaypointsOptimized={handleWaypointsOptimized}
                                />
                                <div className="mt-2">
                                    Distance: {distance} km
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </GoogleMapsProvider>
    );
};

export default withAuth(PickupCreate);
