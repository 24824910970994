import React, {useContext, useState} from "react";
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import axios from "axios";
import {API_URL} from "../constants";
import {AuthContext} from "./AuthContext";
import {useNavigate} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

const CardForm = ({ onCardAdded, userId, onError }) => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const {token, setToken} = useContext(AuthContext);

    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements || !userId) {
            // Stripe.js has not yet loaded.
            return;
        }

        setIsSubmitted(true);
        axios.post(`${API_URL}/api/admin/users/${userId}/setup-intent`, {}, {
            headers: {'Authorization': `Bearer ${token}`},
        })
            .then(async response => {
                let email = response.data?.email;
                let client_secret = response.data?.client_secret;
                if (!email || !client_secret) return;

                const cardElement = elements.getElement(CardElement);

                // Confirming the card setup
                const { setupIntent, error } = await stripe.confirmCardSetup(client_secret, {
                    payment_method: {
                        card: cardElement,
                        billing_details: { email: response.data?.email },
                    },
                });
                setIsSubmitted(false);

                if (error) {
                    console.error('[error]', error);
                    onError(error.message);
                }
                else {
                    if (setupIntent?.status === 'succeeded') {
                        cardElement.clear();
                        onCardAdded();
                    }
                }
            })
            .catch (error => {
                setIsSubmitted(false);
                console.error('Error fetching payment methods', error);
                onError(error.toString());
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
            });
    };

    return (
        <form onSubmit={handleSubmit} align="center">
            <div className="mb-2" align="center">
                <div style={{maxWidth: '400px'}}>
                    <CardElement options={{
                        hidePostalCode: true,
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#32325d',
                            },
                        }
                    }} onChange={(event) => {
                        setButtonDisabled(!event.complete);
                    }} />
                </div>
            </div>
            <button type="submit" className="btn btn-primary" disabled={buttonDisabled}>
                {isSubmitted
                    ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;Adding Card...</>
                    : "Add Card"
                }
            </button>
        </form>
    );
};

export default CardForm;

