import React, {useContext, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../components/AuthContext';
import { API_URL } from '../constants';
import axios from 'axios';
import Spinner from "react-bootstrap/Spinner";

const AdminLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const navigate = useNavigate();
    const { setToken, setAdmin } = useContext(AuthContext);

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
            navigate('/dashboard');
        }
    }, [navigate]);

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        if (name === 'email') {
            setEmail(value);
        } else if (name === 'password') {
            setPassword(value);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        setErrorMessage('');
        setIsSubmitted(true);
        axios.post(`${API_URL}/api/admin/login`, {
            email: email,
            password: password
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                const data = response.data;
                if (data.token) {
                    localStorage.setItem('authToken', data.token);
                    setToken(data.token);
                    setAdmin(data.admin);
                    navigate('/dashboard');
                }
                else {
                    navigate('/2fa');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                if (error.response && error.response.data && error.response.data.error) {
                    setErrorMessage('Invalid credentials. Please try again.');
                } else {
                    setErrorMessage('An error occurred. Please try again later.');
                }
            })
            .finally(() => {
                setIsSubmitted(false);
            });
    };

    return (
        <div className="container mt-5 mb-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <h2 className="text-center mb-4">Login</h2>
                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email" name="email" className="form-control" value={email} onChange={handleChange} required />
                        </div>
                        <div className="form-group mb-2">
                            <label>Password</label>
                            <input type="password" name="password" className="form-control" value={password} onChange={handleChange} required />
                        </div>
                        <button type="submit" className="btn btn-primary btn-block">
                            {isSubmitted
                                ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;Submit...</>
                                : "Submit"
                            }
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AdminLogin;
