import React, {useState, useEffect, useContext} from "react";
import axios from "axios";
import withAuth from "../../auth";
import Pagination from "../Pagination";
import moment from 'moment';
import { API_URL } from '../../constants';
import {Link, useNavigate} from "react-router-dom";
import {AuthContext} from "../AuthContext";
import Spinner from "react-bootstrap/Spinner";

const PayoutReports = () => {
    const [reports, setReports] = useState([]);
    const [updatingId, setUpdatingId] = useState(null);
    const [updatingStatusId, setUpdatingStatusId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [sortBy, setSortBy] = useState('start');
    const [sortOrder, setSortOrder] = useState('desc');
    const navigate = useNavigate();
    const {token, setToken} = useContext(AuthContext);

    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        fetchReports();
    }, [currentPage, sortBy, sortOrder]);

    const fetchReports = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${API_URL}/api/admin/driver-payout-reports`,
                {
                    sort_by: sortBy,
                    sort_order: sortOrder,
                    page: currentPage
                },
                {headers: {'Authorization': `Bearer ${token}`}}
            );
            setReports(response.data.data);
            setTotalPages(response.data.last_page);
        } catch (error) {
            console.error("There was an error fetching the reports!", error);
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSortChange = (column) => {
        if (sortBy === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(column);
            setSortOrder('asc');
        }
        setCurrentPage(1); // reset pagination when sort
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleChange = (e, id) => {
        const { name, value } = e.target;
        if (name === "status") {
            const status = statusOptions.find(option => option.id === value);
            setReports(reports.map(report =>
                report.id === id ? { ...report, status: status } : report
            ));
        } else {
            setReports(reports.map(report =>
                report.id === id ? {...report, [name]: value} : report
            ));
        }
    };

    const handleUpdate = async (id) => {
        if (!window.confirm("Are you sure that you want to update the report?")) return false;

        setSuccess('');
        setError('');
        setUpdatingId(id);
        const reportToUpdate = reports.find(report => report.id === id);
        try {
            await axios.put(`${API_URL}/api/admin/driver-payout-reports/${id}/update-paid-out`, reportToUpdate, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setSuccess(`Report #${id} has been successfully updated`);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setError(errorMessages);
            } else if (error?.response?.data?.message) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred. Please try again later.');
            }
        } finally {
            setUpdatingId(null);
        }
    };

    const handleUpdateStatus = async (id) => {
        if (!window.confirm("Are you sure that you want to update the report?")) return false;

        setSuccess('');
        setError('');
        setUpdatingStatusId(id);
        const reportToUpdate = reports.find(report => report.id === id);
        try {
            await axios.put(`${API_URL}/api/admin/driver-payout-reports/${id}/update-status`, {status: reportToUpdate.status.id}, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setSuccess(`Report #${id} has been successfully updated`);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setError(errorMessages);
            } else if (error?.response?.data?.message) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred. Please try again later.');
            }
        } finally {
            setUpdatingStatusId(null);
        }
    };

    const statusColors = {
        '0': '#FFA3B5', // not payout
        '1': '#FFFAE5',  // partly payout
        '2': '#CFF3C8',  // paid out
    };

    const statusOptions = [
        { id: '0', name: 'Not paid' },
        { id: '1', name: 'Partly payout' },
        { id: '2', name: 'Paid out' }
    ];

    return (
        <div className="container-fluid mt-3">
            <h2 className="text-center">Payout Reports</h2>
            {success && <div className="alert alert-success">{success}</div>}
            {error && <div className="alert alert-danger">{error}</div>}
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <div className="table-responsive">
                    <table className="table table-sm table-striped text-center">
                        <thead>
                        <tr>
                            <th onClick={() => handleSortChange('id')}>
                                ID <i className={`fa fa-sort${sortBy === 'id' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('start')}>
                                Start <i className={`fa fa-sort${sortBy === 'start' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('end')}>
                                End <i className={`fa fa-sort${sortBy === 'end' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('driver_earnings')}>
                                Earnings, $ <i className={`fa fa-sort${sortBy === 'driver_earnings' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('paid_out')}>
                                Paid out, $ <i className={`fa fa-sort${sortBy === 'paid_out' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('status')}>
                                Status <i className={`fa fa-sort${sortBy === 'status' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('created_at')}>
                                Date <i className={`fa fa-sort${sortBy === 'created_at' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {reports.map((report) => (
                            <tr key={report.id}>
                                <td style={{ backgroundColor: statusColors[report.status.id] || 'initial' }}>
                                    <Link to={`${report.report_url}`}>
                                        {report.id}
                                    </Link>
                                </td>
                                <td>{moment(report.start).format('YYYY-MM-DD')}</td>
                                <td>{moment(report.end).format('YYYY-MM-DD')}</td>
                                <td>{report.driver_earnings}</td>
                                <td style={{width: '12%', minWidth: '150px'}}>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            name="paid_out"
                                            value={parseFloat(report.paid_out)}
                                            onChange={(e) => handleChange(e, report.id)}
                                            className="form-control"
                                        />
                                        <div className="input-group-append">
                                            <button className="btn btn-primary" onClick={() => handleUpdate(report.id)} disabled={updatingId === report.id && updatingId !== null}>
                                                {updatingId === report.id ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <i class="fa fa-save"/>}
                                            </button>
                                        </div>
                                    </div>
                                </td>
                                <td style={{width: '12%', minWidth: '200px'}}>
                                    <div className="input-group">
                                        <select className="custom-select" name="status" value={report.status.id} onChange={(e) => handleChange(e, report.id)}>
                                            <option value="0">Not paid</option>
                                            <option value="1">Partly payout</option>
                                            <option value="2">Paid out</option>
                                        </select>
                                        <div className="input-group-append">
                                            <button className="btn btn-primary" onClick={() => handleUpdateStatus(report.id)} disabled={updatingStatusId === report.id && updatingStatusId !== null}>
                                                {updatingStatusId === report.id ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <i class="fa fa-save"/>}
                                            </button>
                                        </div>
                                    </div>
                                </td>
                                <td>{moment(report.created_at).format('YYYY-MM-DD HH:mm')}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {totalPages > 1 && currentPage <= totalPages &&
                        <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange}/>
                    }
                </div>
            )}
        </div>
    );
};

export default withAuth(PayoutReports);
